import React from "react";
import SideBare from "../../components/sideBare";
import KamasTable from "../../components/KamasTable";

const KamasDashboard = () => {
    return (<div className="w-full h-full">
        <KamasTable />
    </div>)
}

export default KamasDashboard;