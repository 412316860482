import axios from 'axios';
export const baseURL = 'https://prokamas.com';

const instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
});

export default instance;