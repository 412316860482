import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppBareProvider from './context/AppBareContext/AppBareProvider';
import SideBareProvider from './context/SideBareContext/SideBareProvider';
import AlertProvider from './context/AlertContext/alertProvide';
import AuthProvider from './context/AuthContext/AuthProvider';
import { BrowserRouter, HashRouter } from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <AppBareProvider>
          <SideBareProvider>
            <AlertProvider>
              <App />
            </AlertProvider>
          </SideBareProvider>
        </AppBareProvider>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
