import React, { useEffect, useRef, useState, useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import AlertContext from '../context/AlertContext/allertContext';
import { Progress } from "@material-tailwind/react";
import ReactSelect from 'react-select';
import Kamas from "../models/kamas";
import AuthContext from '../context/AuthContext/AuthContext'
import Serveres from "../models/Serveres";

const KamasForm = ({ setShowFormular, data, setSelectedCat, loadData }) => {
    const { showAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState(false);
    const error = '';

    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [showFileRequiredError, setShowFileRequiredError] = useState(false);

    const [progress, setProgress] = useState(0);

    const [status, setStatus] = useState([]);

    const { token } = useContext(AuthContext);

    const [list, steList] = useState();

    const [selectedServer, setSelectedServer] = useState([]);

    const listStatus = [
        { value: 'inStock', label: 'Complet', },
        { value: 'notInStock', label: 'Incomplet', },
    ];

    useEffect(() => {
        if (data != undefined) {
            setSelectedServer({ value: data.Server.id, label: data.Server.name })
            if (data.status == 'inStock')
                setStatus({ value: 'inStock', label: 'Complet', },)
            else
                setStatus({ value: 'notInStock', label: 'Incomplet', },)
        }
        const loadServers = async () => {
            const data = await Serveres.getAllServers(false);
            const servers = data.map(s => {
                return { value: s.id, label: s.name, };
            })
            steList(servers);
        }
        loadServers();
    }, []);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: data != undefined ? data.Server.name : '',
            buying_price_USDT: data != undefined ? data.buying_price_USDT : 0,
            buying_price_CIH: data != undefined ? data.buying_price_CIH : 0,
            buying_price_Skrill: data != undefined ? data.buying_price_Skrill : 0,
            selling_price_CIH: data != undefined ? data.selling_price_CIH : 0,
            selling_price_USDT: data != undefined ? data.selling_price_USDT : 0,
            selling_price_Skrill: data != undefined ? data.selling_price_Skrill : 0,
            selling_price_Paypal: data != undefined ? data.selling_price_Paypal : 0,
            qte_in_stock: data != undefined ? data.selling_price_Paypal : 0
        },
        validationSchema: Yup.object({
            name: Yup.string(),
            // name: Yup.string().required("Please Enter city name"),
            buying_price_USDT: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix de vente USDT"),
            buying_price_CIH: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix de vente CIH"),
            buying_price_Skrill: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix de vente Skrill"),
            selling_price_CIH: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix d'achat CIH"),
            selling_price_USDT: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix d'achat USDT"),
            selling_price_Skrill: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix d'achat Skrill"),
            selling_price_Paypal: Yup.number().positive('le prix doit etre positive no null').required("Entrez le prix d'achat Paypal"),
            qte_in_stock: Yup.number('la quqntite en stocke doit etre positive no null').positive().required("Entrez le qunatie en stock")
        }),
        onSubmit: async (values) => {
            if (loading == false) {
                setLoading(true);
                if (data == undefined) {
                    try {
                        await Kamas.create({
                            name: values.name,
                            buying_price_USDT: values.buying_price_USDT,
                            buying_price_CIH: values.buying_price_CIH,
                            buying_price_Skrill: values.buying_price_Skrill,
                            selling_price_CIH: values.selling_price_CIH,
                            selling_price_USDT: values.selling_price_USDT,
                            selling_price_Skrill: values.selling_price_Skrill,
                            selling_price_Paypal: values.selling_price_Paypal,
                            qte_in_stock: values.qte_in_stock,
                            status: 'notInStock',
                            serverId: selectedServer.value
                        }, token);
                        await loadData();
                        setShowFormular(false);
                        showAlert('Kamas a été crée avec succès');
                    } catch (e) {
                        setLoading(false);
                    }
                } else {
                    try {
                        await Kamas.update(data.id, {
                            name: values.name,
                            buying_price_USDT: values.buying_price_USDT,
                            buying_price_CIH: values.buying_price_CIH,
                            buying_price_Skrill: values.buying_price_Skrill,
                            selling_price_CIH: values.selling_price_CIH,
                            selling_price_USDT: values.selling_price_USDT,
                            selling_price_Skrill: values.selling_price_Skrill,
                            selling_price_Paypal: values.selling_price_Paypal,
                            qte_in_stock: values.qte_in_stock,
                            status: status.value,
                            serverId: selectedServer.value
                        }, token);
                        await loadData();
                        setShowFormular(false);
                        showAlert('Kamas a été mise à jour avec succès');
                    } catch (e) {
                        setLoading(false);
                    }
                }
            }
            setLoading(false);
        }
    });

    return (<form onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
    }} action="#">
        <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 ">
            <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        {data != undefined && <>
                            <label className='block' htmlFor="id">id</label>
                            <h1 className="w-full bg-gray-100 p-2 mt-2 mb-3 text-gray-400">{data.id}</h1>
                        </>}
                        {/* <div>
                            <label className='block font-bold text-xl' htmlFor="name">nom</label>
                            <input
                                name="name"
                                className="w-full bg-gray-100 p-2 mt-2"
                                placeholder="Enter le nom"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.name}</h1>
                            ) : null}
                        </div> */}

                        <div>
                            <label className='block font-bold text-xl py-2' htmlFor="server">Server</label>
                            <ReactSelect
                                className=""
                                value={selectedServer}
                                isMulti={false}
                                options={list}
                                onChange={(val) => {
                                    setSelectedServer(val);
                                }}
                                menuShouldScrollIntoView={true}
                                maxMenuHeight={200}
                            // options={dissortbyMulti}
                            // classNamePrefix="js-example-disabled-multi mb-0"
                            // isDisabled={fa}
                            />
                        </div>
                        <div>
                            <label className='block font-bold text-xl py-2' htmlFor="server">Status</label>
                            <ReactSelect
                                className=""
                                value={status}
                                isMulti={false}
                                options={listStatus}
                                onChange={(val) => {
                                    setStatus(val);
                                }}
                                menuShouldScrollIntoView={true}
                                maxMenuHeight={200}
                            // options={dissortbyMulti}
                            // classNamePrefix="js-example-disabled-multi mb-0"
                            // isDisabled={fa}
                            />
                        </div>
                        <h1 className="font-bold text-xl pt-2">USDT</h1>
                        <div className=" flex flex-row gap-4">
                            <div>
                                <label className='block text-green-600 font-bold' htmlFor="name">Prix de vente USDT</label>
                                <input
                                    name="buying_price_USDT"
                                    className="w-full bg-gray-100 p-2 mt-2 mb-3 text-green-600 font-bold"
                                    placeholder="Enter le prix"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.buying_price_USDT || ""}
                                // invalid={
                                //     validation.touched.name && validation.errors.name ? true : false
                                // }
                                />
                                {validation.touched.buying_price_USDT && validation.errors.buying_price_USDT ? (
                                    <h1 className='text-red-500 font-semibold'>{validation.errors.buying_price_USDT}</h1>
                                ) : null}
                            </div>

                            <div>
                                <label className='block text-red-600 font-bold' htmlFor="name">Prix d'achat USDT</label>
                                <input
                                    name="selling_price_USDT"
                                    className="w-full bg-gray-100 p-2 mt-2 mb-3 text-red-600 font-bold"
                                    placeholder="Enter le prix"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.selling_price_USDT || ""}
                                // invalid={
                                //     validation.touched.name && validation.errors.name ? true : false
                                // }
                                />
                                {validation.touched.selling_price_USDT && validation.errors.selling_price_USDT ? (
                                    <h1 className='text-red-500 font-semibold'>{validation.errors.selling_price_USDT}</h1>
                                ) : null}
                            </div>
                        </div>

                        <h1 className="font-bold text-xl">CIH</h1>
                        <div className="w-full flex flex-row gap-4">
                            <div>
                                <label className='block text-green-600 font-bold' htmlFor="name">Prix de vente CIH</label>
                                <input
                                    name="buying_price_CIH"
                                    className="w-full bg-gray-100 p-2 mt-2 mb-3 text-green-600 font-bold"
                                    placeholder="Enter le prix"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.buying_price_CIH || ""}
                                // invalid={
                                //     validation.touched.name && validation.errors.name ? true : false
                                // }
                                />
                                {validation.touched.buying_price_CIH && validation.errors.buying_price_CIH ? (
                                    <h1 className='text-red-500 font-semibold'>{validation.errors.buying_price_CIH}</h1>
                                ) : null}
                            </div>
                            <div>
                                <label className='block text-red-600 font-bold' htmlFor="name">Prix d'achat CIH</label>
                                <input
                                    name="selling_price_CIH"
                                    className="w-full bg-gray-100 p-2 mt-2 mb-3 text-red-600 font-bold"
                                    placeholder="Enter le prix d'achat Skrill"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.selling_price_CIH || ""}
                                // invalid={
                                //     validation.touched.name && validation.errors.name ? true : false
                                // }
                                />
                                {validation.touched.selling_price_CIH && validation.errors.selling_price_CIH ? (
                                    <h1 className='text-red-500 font-semibold'>{validation.errors.selling_price_CIH}</h1>
                                ) : null}
                            </div>

                        </div>


                        <h1 className="font-bold text-xl">Skrill</h1>
                        <div className="w-full flex flex-row gap-4">
                            <div>
                                <label className='block text-green-600 font-bold' htmlFor="name">Prix de vente Skrill</label>
                                <input
                                    name="buying_price_Skrill"
                                    className="w-full bg-gray-100 p-2 mt-2 mb-3 text-green-600 font-bold"
                                    placeholder="Enter le prix"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.buying_price_Skrill || ""}
                                // invalid={
                                //     validation.touched.name && validation.errors.name ? true : false
                                // }
                                />
                                {validation.touched.buying_price_Skrill && validation.errors.buying_price_Skrill ? (
                                    <h1 className='text-red-500 font-semibold'>{validation.errors.buying_price_Skrill}</h1>
                                ) : null}
                            </div>

                            <div>
                                <label className='block text-red-600 font-bold' htmlFor="name">Prix d'achat Skrill</label>
                                <input
                                    name="selling_price_Skrill"
                                    className="w-full bg-gray-100 p-2 mt-2 mb-3 text-red-600 font-bold"
                                    placeholder="Enter le prix"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.selling_price_Skrill || ""}
                                // invalid={
                                //     validation.touched.name && validation.errors.name ? true : false
                                // }
                                />
                                {validation.touched.selling_price_Skrill && validation.errors.selling_price_Skrill ? (
                                    <h1 className='text-red-500 font-semibold'>{validation.errors.selling_price_Skrill}</h1>
                                ) : null}
                            </div>
                        </div>

                        <h1 className="font-bold text-xl">Paypal</h1>
                        <div>
                            <label className='block text-red-500 font-semibold' htmlFor="name">Prix d'achat Paypal</label>
                            <input
                                name="selling_price_Paypal"
                                className="w-full bg-gray-100 p-2 mt-2 mb-3 text-red-500 font-semibold"
                                placeholder="Enter le prix d'achat Skrill"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.selling_price_Paypal || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.selling_price_Paypal && validation.errors.selling_price_Paypal ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.selling_price_Paypal}</h1>
                            ) : null}
                        </div>

                        <div>
                            <label className='block font-bold text-xl' htmlFor="name">Quantite en stcok</label>
                            <input
                                name="qte_in_stock"
                                className="w-full bg-gray-100 p-2 mt-2 mb-3"
                                placeholder="Enter le prix d'achat Skrill"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.qte_in_stock || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.qte_in_stock && validation.errors.qte_in_stock ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.qte_in_stock}</h1>
                            ) : null}
                        </div>

                    </div>
                    <div className="w-full">
                        <Progress className='h-2' color='amber' value={progress} variant="filled" />
                    </div>
                    <div className="bg-gray-200 px-4 py-3 text-right">
                        <button onClick={() => { setSelectedCat(undefined); setShowFormular(false); }} type="button" className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2">Annuler</button>
                        <button color="success" disabled={error ? null : loading ? true : false} className="py-2 px-4 bg-amber-500 text-white rounded hover:bg-amber-600 mr-2" type="submit">
                            {error ? null : loading ? <h1 className='text-sm me-2'> Loading... </h1> : data != undefined ? 'Mettre à jour' : 'Créer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form >)
}

export default KamasForm;