import React, { useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import instance from '../../api/axiosConfig';
import jwt from 'jwt-decode';


function AuthProvider({ children }) {
    const [token, setToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const login = async (email, password) => {
        try {
            let response = await instance.post('/api/auth/login', { "email": email, "password": password }, {
                headers: {
                    'Content-Type': "application/json",
                    "Accept": "application/json"
                }
            });
            const jwtDecoded = jwt(response.data.token);
            setUserInfo(jwtDecoded.user);
            setToken(response.data.token);
            localStorage.setItem('token', response.data.token);
            return jwtDecoded.user.role;
        } catch (e) {
            throw e;
        }

    }

    const logout = async () => {
        localStorage.removeItem('token');
        setToken(null);
        setUserInfo(null);
    }

    const signUp = async (name, lastName, email, password) => {
        let response = await instance.post('/api/auth/register', {
            "name": name,
            "lastName": lastName,
            "email": email,
            "password": password
        }, {
            headers: {
                'Content-Type': "application/json",
                "Accept": "application/json"
            }
        });
    }

    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token != null) {
            const jwtDecoded = jwt(token);
            if (jwtDecoded.exp < Date.now() / 1000) {
                logout();
            } else {
                setUserInfo(jwtDecoded.user);
                setToken(token);
            }

        }
        // logout();
        //setUserInfo({ role: 'admin' });
    }, []);

    return <AuthContext.Provider value={{ login, signUp, userInfo, token, logout }}>{children}</AuthContext.Provider>;
}

export default AuthProvider;