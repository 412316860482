import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiMapPin, FiLifeBuoy, FiDisc, FiCalendar } from "react-icons/fi";
import SideBareContext from '../context/SideBareContext/SideBareContext';
import logo from '../assets/logo.png';
import AuthContext from '../context/AuthContext/AuthContext';

const SideBare = () => {
    const items = [
        //     {
        //     name: 'Aperçu général',
        //     route: '/',
        //     icon: <FiMapPin />
        // }, 
        {
            name: 'Kamas',
            route: '/admin/kamas',
            icon: <FiMapPin />
        }, {
            name: 'Servers',
            route: '/admin/server',
            icon: <FiDisc />
        }, {
            name: 'Commande',
            route: '/admin/command',
            icon: <FiCalendar />
        },
        {
            name: 'Vente',
            route: '/admin/vente',
            icon: <FiCalendar />
        },
        {
            name: 'Exchage',
            route: '/admin/exhange',
            icon: <FiCalendar />
        },]



    const { activeRoute, setActiveRoute } = useContext(SideBareContext);

    const { logout } = useContext(AuthContext);
    //bg-[#2F3230]
    return (
        <div className='flex flex-col justify-between bg-gray-800 py-8 px-4 w-80 sm:w-1/2 md:w-80 h-full  shadow sm:shadow-md md:shadow-lg lg:shadow-xl xl:shadow-2xl border border-gray-900'>
            <div className="h-full">
                <div className='h-1/6 flex flex-col justify-center'>
                    <img src={logo} className="mx-auto" />
                </div>

                <ul className="text-gray-700" >
                    {items.map(v => (
                        <li key={v.name} className={`block cursor-pointer hover:bg-main hover:text-white p-2 m-2 rounded-lg ${v.route == activeRoute ? "text-white bg-main" : "text-gray-200"}  hover:text-main`}>
                            <NavLink key={v.name} onClick={() => setActiveRoute(v.route)} to={v.route} className="flex flex-row items-center" href="#">
                                <div className='text-xl ml-2 mr-4' >
                                    {v.icon}
                                </div>
                                <div className='text-center text-xl'>{v.name}</div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='mx-4'>
                <button onClick={async () => {
                    await logout();
                }} color="success" className="py-2 px-4 w-full hover:bg-main bg-gray-700 text-white rounded">
                    Déconnexion</button>
            </div>
        </div>

    )
    {/* 
        <div classNameName='h-full w-3/12 flex flex-col py-8'>
            <div classNameName='h-1/6'>
                <h1 classNameName='text-black text-center'>Girouette</h1>
            </div>
            <div classNameName='flex flex-col h-5/6'>
                {items.map(v => (<NavLink key={v.name} to={v.route}><div classNameName='flex flex-row py-5'>
                    <h1 classNameName='flex-1 text-center text-xl'>{v.name}</h1>
                    <div classNameName='bg-blue-500 w-1'></div>
                </div>
                </NavLink>))}
            </div>
        </div> */}
}

export default SideBare