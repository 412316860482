import React, { useState, useEffect, useContext } from 'react';
import SideBare from '../../components/sideBare'
import { HashRouter, Outlet, Route, Router } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Alert from '../../components/alert';
import AlertContext from '../../context/AlertContext/allertContext';

const HomePageDashboard = () => {
    const { show, showAlert } = useContext(AlertContext);

    useEffect(() => {
        // onAuthStateChanged(auth, (user) => {
        //     if (user) {
        //         // User is signed in, see docs for a list of available properties
        //         // https://firebase.google.com/docs/reference/js/firebase.User
        //         const uid = user.uid;
        //         // ...
        //         console.log("uid", uid)
        //     } else {
        //         // User is signed out
        //         // ...
        //         console.log("user is logged out")
        //     }
        // });

    }, [])

    return (
        <div className='w-full h-screen flex flex-row'>
            <SideBare />
            {show && <Alert />}
            <div className='w-10/12 h-full flex flex-col'>
                <Header />
                <Outlet />
                <Footer />
            </div>
        </div>
    )
}

export default HomePageDashboard