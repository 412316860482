import instance from "../api/axiosConfig";

class Serveres {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.category = data.category;
    }

    static async create(data, token) {
        try {
            // const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTY4OTcwMDkzMSwiZXhwIjoxNjg5NzA0NTMxfQ.G8Fr-nMbb3X4yWKNk74Ln7v3Vlj8rmhKQNhTt-b8NOs' }
            let response = await instance.post('/api/server', data, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            //return response.data;
        } catch (e) {
            throw e;
        }

    }

    static async delete(id, token) {
        try {
            let response = await instance.delete(`/api/server/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async getAllServers(isRequired = true) {
        try {
            let response = await instance.get(`/api/server?isRequired=${isRequired}`);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async update(id, data, token) {
        try {
            let response = await instance.put(`/api/server/${id}`, data, { headers: { 'Authorization': `Bearer ${token}` } });
            return response.data;
        } catch (e) {
            throw e;
        }
    }
}

export default Serveres;