import logo from '../assets/logo.png';


export default function ComponentLoader() {
    return (
        <div className='w-full h-32 flex flex-row text-center text-xl font-bold justify-center items-center'>
            <div className="animate-pulse m-3 duration-1000 flex space-x-4  rounded-full w-10 h-10 bg-gradient-to-br p-2 ">
                {/*<img src={logo} alt="loader" />*/}
                <svg className="w-10 h-10 text-orange-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                    <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                </svg>
            </div>
        </div>
    )
}