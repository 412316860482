import React, { useEffect, useState } from 'react';
import SideBareContext from './SideBareContext';
import { useLocation } from 'react-router-dom';

function SideBareProvider({ children }) {
    const [activeRoute, setActiveRoute] = useState('/');
    const location = useLocation();
    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [])

    return <SideBareContext.Provider value={{ activeRoute, setActiveRoute }}>{children}</SideBareContext.Provider>;
}

export default SideBareProvider;