import React, { useState, useEffect } from 'react';
import AppBare from '../components/appBare';
import { useNavigate } from 'react-router-dom';
import background from '../assets/dofusLogo.jpg'

import exchangeLogo from '../assets/exchange.png'
import buyLogo from '../assets/buy.png'
import sellLogo from '../assets/sell.png'

import deleveriLogo from '../assets/timer.png'
import supportLogo from '../assets/support.png'
import piement from '../assets/wallet.png'

const HomePage = () => {
    const navigate = useNavigate();
    useEffect(() => {

        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/6640ee7b07f59932ab3eb44a/1htmq74t0';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();

        // const script = document.createElement('script');

        // script.src = "https://use.typekit.net/foobar.js";
        // script.async = true;

        // document.body.appendChild(script);

        // return () => {
        //     document.body.removeChild(script);
        // }
    }, []);
    return (
        <div className='w-full h-full flex flex-col pb-10 overflow-auto relative' 
        style={{
            backgroundImage: `url('${background}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            minHeight: '100vh'
        }}>
            <AppBare />
            <div className='flex flex-col flex-1 justify-center items-center z-20 space-y-20 pt-24'>
                <div className='flex flex-col text-3xl font-bold'>
                    <h1>Bienvenue sur <span className='text-main'>ProKamas</span> : Votre destination de confiance</h1>
                    <h1>pour des transactions de <span className='text-main'>Kamas</span> simples et sécurisées !</h1>
                </div>
                <div className='flex flex-row gap-6'>
                    <div className='flex flex-col items-center bg-sky-500 bg-opacity-60 border-4 border-sky-900 backdrop-blur-lg rounded-2xl p-10 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300' onClick={() => {
                        navigate('/acheter-kamas-dofus');
                    }}>
                        <img className='w-28 h-28' src={buyLogo} />
                        <h1 className='text-xl font-semibold text-white pt-4'>Acheter Kamas</h1>
                    </div>

                    <div className='flex flex-col items-center bg-sky-500 bg-opacity-60 border-4 border-sky-900 backdrop-blur-lg rounded-2xl p-10 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300' onClick={() => {
                        navigate('/vendre-kamas-dofus');
                    }}>
                        <img className='w-28 h-28' src={sellLogo} />
                        <h1 className='text-xl font-semibold text-white  pt-4'>Vendre Kamas</h1>
                    </div>
                    <div className='flex flex-col items-center bg-sky-500 bg-opacity-60 border-4 border-sky-900 backdrop-blur-lg rounded-2xl p-10 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300' onClick={() => { navigate('/echange-kamas-dofus') }}>
                        <img className='w-28 h-28' src={exchangeLogo} />
                        <h1 className='text-xl font-semibold text-white  pt-4'>Echanger Kamas</h1>
                    </div>
                </div>
            </div>
            <div className='z-20 p-10 flex justify-center space-x-6'>
                    <div className='w-80 flex flex-col justify-center items-center rounded-xl border-4 border-black p-5 bg-gray-900 bg-opacity-50 backdrop-blur-lg'>
                        <div>
                            <img className='w-28 h-28' src={deleveriLogo} />
                        </div>
                        <h1 className='text-xl text-cyan-500 font-bold'>LIVRAISON RAPIDE</h1>
                        <h1 className='text-xs text-center text-white font-semibold'>Plus de 85% des commandes livrées en moins de 10 minutes</h1>
                    </div>
                    <div className='w-80 flex flex-col justify-center items-center rounded-xl border-4 border-black p-5 bg-gray-900 bg-opacity-50 backdrop-blur-lg'>
                        <div>
                            <img className='w-28 h-28' src={supportLogo} />
                        </div>
                        <h1 className='text-xl text-cyan-500 font-bold'>SUPPORT CLIENT 24/7J</h1>
                        <h1 className='text-xs text-center text-white font-semibold'>SUPPORT CLIENT - ASSISTANCE clients Agents expérimentes-Support multilingue</h1>
                    </div>
                    <div className='w-80 flex flex-col justify-center items-center rounded-xl border-4 border-black p-5 bg-gray-900 bg-opacity-50 backdrop-blur-lg'>
                        <div>
                            <img className='w-28 h-28' src={piement} />
                        </div>
                        <h1 className='text-xl text-cyan-500 font-bold'>PAIEMENT Sécurisé</h1>
                        <h1 className='text-xs text-center text-white font-semibold'>Système de paiement 100% sécurisé et approuvé par une centaine de clients</h1>
                    </div>
            </div>
        </div >
    )
}

export default HomePage;