import React from "react";

import Lottie from "lottie-react";
import logoLottie from "../assets/102652-game-app.json"

const Loader = () => {
    return <div className='w-full h-full bg-white flex flex-col justify-center items-center'>
        {/* <img className="w-64" src={logo} /> */}
        <Lottie animationData={logoLottie} />
    </div>;
}
export default Loader;