import React, { useContext, useEffect, useState } from "react";
import AppBare from "../components/appBare";
import Banner from "../components/banner";
import ReactSelect from 'react-select';

import Lottie from "lottie-react";
import gameLottie from "../assets/11751-meda-shi-live-trivia-game.json"

import cihLogo from '../assets/cih.png'
import USDTLogo from '../assets/usdtLogo.png'
import SkrillLogo from '../assets/Skrill.png'
import Paypal from '../assets/paypal.png'
import Serveres from "../models/Serveres";
import PriceTable from "../components/priceTable";
import Sellingorder from "../models/Sellingorder";
import AuthContext from "../context/AuthContext/AuthContext";
import background from "../assets/dofusLogo.jpg";
import { useNavigate } from "react-router-dom";

const SellKamasPage = () => {

    const navigate = useNavigate();

    const [counter, setCounter] = useState(0);

    const [listServer, setlistServer] = useState([]);
    useEffect(() => {
        const loadData = async () => {
            try {
            let response = await Serveres.getAllServers();
            let filtredData = response.filter((v)=> v.Kama.status != 'inStock')
            let data = filtredData.map(v => {
                return {
                    value: v.Kama.id,
                    label: v.name,
                    prixAchatCIH: parseFloat(v.Kama.selling_price_CIH),
                    prixAchatSkrill: parseFloat(v.Kama.selling_price_Skrill),
                    prixAchatUSDT: parseFloat(v.Kama.selling_price_USDT),
                    prixAchatPaypal: parseFloat(v.Kama.selling_price_Paypal),
                    min_kamas_sell: parseFloat(v.min_kamas_sell)
                }
            })
            setlistServer(data);
            }catch {
                setlistServer([]);
            }
        }
        loadData();
    }, [])

    const [persoError, setPersoError] = useState(false);
    const [serverErrror, setServerErrror] = useState(false);
    const [paiementInfoError, setpaiementInfoErro] = useState(false);
    const [methodPError, setMethodPErro] = useState(false);


    const paymentMethode = [
        { name: 'cih', logo: cihLogo},
        { name: 'Skrill', logo: SkrillLogo },
        { name: 'USDT', logo: USDTLogo },
        { name: 'Paypal', logo: Paypal }
    ];
    const [selectedPMethod, setSelectedPMethod] = useState();

    const [selectedServer, setSelectedServer] = useState();
    const { token, userInfo } = useContext(AuthContext);

    const [price, setprice] = useState(0);
    const [pesoName, setPersoName] = useState('');

    const [paiementInfo, setPaiementInfo] = useState('');


    const onChangeServer = (server) => {
        setCounter(server.min_kamas_sell)
        setSelectedServer(server)
        if (selectedPMethod == undefined) {
            return
        }
        
        onPaiementMethodeChange(selectedPMethod, server)
    }

    const onPaiementMethodeChange = (methodName, server) => {
        setSelectedPMethod(methodName)
        if (server == undefined) {
            return
        }
        if (methodName == 'cih') {
            setprice(server.prixAchatCIH);
        } else {
            if (methodName == 'Skrill') {
                setprice(server.prixAchatSkrill);
            } else {
                if (methodName == 'USDT') {
                    setprice(server.prixAchatUSDT);
                } else {
                    setprice(server.prixAchatPaypal)
                }
            }
        }
    }

    return (
        <div className="w-full h-full flex flex-col overflow-auto">
            <AppBare showLogo/>
            <div className="flex-1 flex flex-row px-10 pb-10 z-20 mt-28 gap-10">
                <div className="w-1/2 h-full flex flex-col items-center rounded-xl gap-5">
                     <PriceTable isSelling />
                </div>
                <div className="w-1/2 flex flex-col gap-10">
                    <div className="w-full py-20 flex flex-col justify-between bg-gray-500  p-10 rounded-xl bg-opacity-10 backdrop-blur-sm">
                        <div className="w-full">
                            <h1 className="pb-5 text-xl">Serveur :</h1>
                            <ReactSelect
                                className=""
                                value={selectedServer}
                                isMulti={false}
                                options={listServer}
                                onChange={(server) => {
                                    onChangeServer(server)
                                }}
                                menuShouldScrollIntoView={true}
                                maxMenuHeight={200}
                            // options={dissortbyMulti}
                            // classNamePrefix="js-example-disabled-multi mb-0"
                            // isDisabled={fa}
                            />
                            {serverErrror && <h1 className="text-red-500">Sélectionner un serveur</h1>}
                        </div>
                        <div className="flex flex-col pt-5 w-full">
                            <h1 className="pr-5 text-lg pb-4 text-main ">La quantité minimale de kamas à nous vendre est : 
                                <span className="text-lg font-bold text-main ">{selectedServer == undefined ? 10 : selectedServer.min_kamas_sell} M</span>
                            </h1>
                            <h1 className="pr-5 text-xl pb-4">Quantité :</h1>
                            <div className="flex flex-row">
                                <div className="border-2 border-gray-600 rounded-xl flex flex-row font-bold">
                                    <button className="px-5 py-3 cursor-pointer" onClick={() => {
                                        let min = selectedServer == undefined ? 10 : selectedServer.min_kamas_sell
                                        if (counter > min) {
                                            setCounter(counter - min);
                                        }
                                    }}>-</button>
                                    <input type="text" 
                                        id="counter-input" 
                                        data-input-counter 
                                        class="text-gray-900 border-0 bg-transparent focus:outline-none focus:ring-0 text-center" 
                                        placeholder="" 
                                        value={counter} 
                                        required 
                                        onChange={(e) => {
                                            const newValue = Number(e.target.value);
                                            if (!isNaN(newValue)) {
                                                setCounter(newValue)
                                            }
                                        }}
                                        onBlur={(e) => {
                                            let min = selectedServer == undefined ? 10 : selectedServer.min_kamas_sell
                                            const newValue = Number(e.target.value);
                                            if (!isNaN(newValue)) {
                                                if (newValue > min){
                                                    setCounter(newValue)
                                                }else{
                                                    setCounter(min)
                                                }
                                            }
                                        }}
                                    />
                                    <button className="px-5 py-3 cursor-pointer" onClick={() => {
                                        let min = selectedServer == undefined ? 10 : selectedServer.min_kamas_sell
                                        setCounter(counter + min)
                                    }}>+</button>
                                </div>
                            </div>
                            <h1>1M kamas = 1.000.000 kamas</h1>
                        </div>

                        <div className="w-full">
                            <h1 className="py-4">Méthode de paiement :</h1>
                            <div className={`grid grid-cols-4 gap-4 justify-star ${methodPError ? 'border-red-500 border-2 rounded-xl' : ''}`}>
                                {
                                    paymentMethode.map(m => 
                                    <div
                                        onClick={() => {
                                            onPaiementMethodeChange(m.name, selectedServer)
                                        }}
                                        className={`${selectedPMethod == m.name ? 'border-main border-2' : ''} bg-gray-300 px-10 py-4 rounded-xl h-16 flex items-center justify-center`}>
                                        <img className="h-full object-fill" src={m.logo} />
                                        
                                    </div>)
                                }
                                
                            </div>
                            <h1 className="text-xl font-bold text-green-300 pt-2 text-center">Paiement dans 15 à 60 minutes après réception des kamas</h1>
                            {methodPError && <h1 className="text-red-500">Sélectionner un moyen de paiement</h1>}
                        </div>

                        <div className="flex flex-col py-3 w-full">
                            <h1 className="w-full pr-5 text-xl pb-4">{selectedPMethod == 'cih'? 'Numéro de compte' : selectedPMethod=='Skrill'? 'Email Skrill':selectedPMethod=='USDT' ?'Adresse USDT (TRC20)' :  'Email'}</h1>
                            <input onChange={(event) => {
                                setPaiementInfo(event.target.value);
                            }} placeholder={selectedPMethod == 'cih' ? 'Entrer Votre numéro de compte' :  selectedPMethod=='Skrill' ? 'Entrer Votre Email Skrill' :selectedPMethod=='USDT' ? 'Entrer Votre Adresse USDT' :  'Entrer Votre Email'} className="w-full rounded-xl py-2 px-4 border-2 border-gray-600" />
                            {paiementInfoError && <h1 className="text-red-500">Entrer les informations pour le paiement</h1>}
                        </div>


                        <div className="flex flex-col pt-5 w-full">
                            <h1 className="w-full pr-5 text-xl pb-4">Pseudo dofus :</h1>
                            <input onChange={(event) => {
                                setPersoName(event.target.value);
                            }}
                                placeholder="Pseudo dofus" className="w-full rounded-xl py-2 px-4 border-2 border-gray-600" />
                            {persoError && <h1 className="text-red-500">Entrer le Pseudo dofus</h1>}
                        </div>

                        <div className="flex flex-row py-5 w-full">
                            <h1 className="text-xl pb-2 pr-5">Total :</h1>
                            <h1 className="font-bold text-2xl text-main">{(price * counter).toFixed(2)}
                                {selectedPMethod == 'cih' ? ' MAD' : selectedPMethod == 'USDT' ? ' USDT' : ' €'}</h1>
                        </div>

                        <button onClick={() => {
                            if(userInfo == null) {
                                navigate('/se-connecter')
                            } else {
                                if (selectedServer == undefined) {
                                    setServerErrror(true);
                                } else {
                                    setServerErrror(false);
                                    if (selectedPMethod == undefined) {
                                        setMethodPErro(true)
                                    } else {
                                        setMethodPErro(false)
                                        if (paiementInfo == '') {
                                            setpaiementInfoErro(true);
                                        } else {
                                            setpaiementInfoErro(false);
                                            if (pesoName == '') {
                                                setPersoError(true)
                                            } else {
                                                setPersoError(false)
                                                Sellingorder.create({
                                                    qte: counter,
                                                    status: 'EN_ATTENTE_DE_LIVRAISON',
                                                    namePerso: pesoName,
                                                    paiement_method: selectedPMethod,
                                                    kamasId: selectedServer.value,
                                                    userId: userInfo.id,
                                                    paiementInfo: paiementInfo,
                                                    totale: (price * counter),
                                                    sereverName: selectedServer.label
                                                }, token);
                                                navigate('/mes-commande', { state: { selectedpage: 'vente' } });
                                            }
                                        }
                                    }
                                }
                            }
                            


                        }} className="w-full bg-main text-white text-xl px-10 py-4 rounded-xl font-bold">Vendre</button>
                    </div>
                    <div className="border-main border-2 rounded-xl p-5 bg-white bg-opacity-50 backdrop-blur-sm flex flex-col items-center text-lg">
                            <h1 className="text-xl text-black font-bold ">
                                Vente kamas en 4 Étapes :
                            </h1>
                            <h1>
                                <span className="font-bold">1-Commande facile : </span>
                                Choisissez le serveur où vous avez les kamas à vendre, la quantité que vous souhaitez vendre, le moyen de paiement sur lequel vous souhaitez recevoir le paiement et indiquez le pseudo de votre personnage dans le jeu. Une fois les détails de votre vente renseignés, cliquez sur le bouton "Vendre".
                            </h1>
                            <h1>
                                <span className="font-bold">2-Numéro de Commande : </span>
                                Après avoir cliqué sur "Vendre", vous serez redirigé vers une page de confirmation où vous trouverez votre numéro de commande unique. Veuillez conserver ce numéro, vous en aurez besoin pour communiquer avec notre équipe.
                            </h1>
                            <h1>
                                <span className="font-bold">3-Contactez-nous : </span>
                                Utilisez le bouton de support en bas à droite de votre écran pour ouvrir une conversation avec notre équipe de support. Communiquez votre numéro de commande et nous serons prêts à traiter votre vente.
                            </h1>
                            <h1>
                                <span className="font-bold">4-Traitement Rapide : </span>
                                Notre équipe passera rapidement en revue votre commande et vous contactera pour finaliser les détails de la vente et organiser le paiement selon vos préférences.
                            </h1>
                        </div>
                </div>
            </div>
            <img className="w-full h-full absolute z-10" src={background}/>
        </div >
    );
}

export default SellKamasPage;