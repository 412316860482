import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from '../assets/logo.png'
import AuthContext from "../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import background from "../assets/dofusLogo.jpg"

const LoginPage = () => {

    const [error, setError] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const validation = useFormik({
        //enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required("Veuillez entrer votre adresse e-mail."),
            password: Yup.string().required("Veuillez entrer votre mot de passe."),
        }),
        onSubmit: async (values) => {

            setError(false);
            try {
                let userRole = await login(values.email, values.password);
                if (userRole == 'ADMIN') {
                    navigate('/admin');
                } else {
                    navigate('/');
                }
            } catch (e) {
                setError(true);
            }

            // signInWithEmailAndPassword(auth, values.email, values.password)
            //     .then((userCredential) => {
            //         const user = userCredential.user;
            //         //navigate("/")
            //     })
            //     .catch((error) => {
            //         //setError(true)
            //     });

        }
    });
    return (
        <div className="w-full h-full flex flex-row">
           <div className="w-1/2 h-full bg-gray-700 flex flex-col justify-center items-center">
                <img src={background} className="w-full h-full object-cover" />
            </div>
            <div className="w-1/2 bg-white flex flex-row justify-center items-center space-x-3">
                <div className="w-full px-20">
                    <div className="text-center pb-5 flex flex-col items-center w-full gap-5">
                        <img className="w-6/12" src={logo} onClick={() =>{navigate('/')}}/>
                        <h2 className="mb-16 text-3xl font-bold text-orange-600">
                            Bienvenue de retour!
                        </h2>
                        <p className="text-xl font-bold text-gray-700">Connectez-vous d'abord à votre compte.</p>
                    </div>

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }} action="#">
                        <div className="relative">
                            <label className="ml-3 text-sm font-bold text-gray-700 tracking-wide">Adresse e-mail</label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoFocus
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-2xl focus:border-indigo-500"
                                placeholder="Saisissez votre adresse e-mail" />
                            {validation.touched.email && validation.errors.email ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.email}</h1>
                            ) : null}
                        </div>
                        <div className="mt-8 content-center">
                            <label className="ml-3 text-sm font-bold text-gray-700 tracking-wide">
                                Mot de passe
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}

                                className="w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 focus:outline-none focus:border-indigo-500"
                                placeholder="Saisissez votre mot de passe" />
                            {validation.touched.password && validation.errors.password ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.password}</h1>
                            ) : null}
                        </div>
                        <h1 className={`mb-5 text-red-500 ${error ? 'visible' : 'hidden'}`}>Nom d'utilisateur ou mot de passe incorrect.</h1>
                        {/* <div className="flex items-center justify-between">
                                    <div className="flex items-center py-8">
                                        <input id="remember_me" name="remember_me" type="checkbox"
                                            className="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded" />
                                        <label for="remember_me" className="ml-2 block text-sm text-gray-900">
                                            Se souvenir de moi
                                        </label>
                                    </div>
                                    <div className="text-sm py-8">
                                        <a href="#" className="text-amber-400 hover:text-amber-500">
                                            Mot de passe oublié
                                        </a>
                                    </div>
                                </div> */}
                        <div className='pt-8'>
                            <button type="submit"
                                className="w-full flex justify-center bg-gradient-to-r bg-main p-4 rounded-xl tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500 text-white">
                                Connexion
                            </button>
                            
                        </div>
                        <div className="flex flex-row justify-center pt-10 cursor-pointer">
                            <h1 className="text-main underline" onClick={()=>{
                                 navigate('/inscription')
                            }}>Créer un compte</h1>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default LoginPage;