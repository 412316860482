import React, { useState, useEffect, useContext } from "react";
import KamasForm from "./KamasForm";
import AlertContext from "../context/AlertContext/allertContext";
import { baseURL } from "../api/axiosConfig";
import SuppDialog from "./suppDialog";

// import eyeIcon from "../assets/icons/show.svg";
import eyeIcon from "../assets/icons/white_show.svg";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import plusIcon from "../assets/icons/plus.svg";

import Kamas from "../models/kamas"
import ComponentLoader from "./ComponentLoader";
import DataTable from "react-data-table-component";
import AuthContext from "../context/AuthContext/AuthContext";



const KamasTable = () => {

    const [showFormular, setShowFormular] = useState(false);
    const [isConsulting, setIsConsulting] = useState(false);
    const [selectedKamas, setSelectedKamas] = useState(undefined);

    const [data, setData] = useState([]);

    const [displayedData, setDisplayedData] = useState([]);

    const { showAlert } = useContext(AlertContext);

    const [loding, setLoding] = useState(true);

    const [open, setIsOpen] = useState(false);

    const { token } = useContext(AuthContext);

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "white",
                backgroundColor: "#1F2937",
            },
        },
    };

    const columns = [
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">ID</span>
            ),
            selector: (row) =>
                row.id,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Status</span>
            ),
            selector: (row) =>
                row.status == 'inStock' ? 'Complet' :   'Incomplet',
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Nom serveur</span>
            ),
            selector: (row) =>
                row.Server.name,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Vente CIH</span>
            ),
            selector: (row) =>
                row.buying_price_CIH,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Vente Skrill</span>
            ),
            selector: (row) =>
                row.buying_price_Skrill,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Vente USDT</span>
            ),
            selector: (row) =>
                row.buying_price_USDT,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Achat CIH</span>
            ),
            selector: (row) =>
                row.selling_price_CIH,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Achat USDT</span>
            ),
            selector: (row) =>
                row.selling_price_USDT,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Achat Skrill</span>
            ),
            selector: (row) =>
                row.selling_price_Skrill,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Achat Paypal</span>
            ),
            selector: (row) =>
                row.selling_price_Paypal,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold mx-auto">
                    Actions
                </span>
            ),
            sortable: false,

            cell: (row) => {
                return (
                    <div className="flex mx-auto flex-row gap-4">
                        <img
                            src={editIcon}
                            alt="edit"
                            className=" cursor-pointer"
                            onClick={() => {
                                setSelectedKamas(row);
                                setShowFormular(true);
                            }}
                        />

                        <img
                            src={deleteIcon}
                            alt="delete"
                            className="cursor-pointer"
                            onClick={() => {
                                setIsOpen(true);
                                setSelectedKamas(row);
                            }}
                        />
                    </div>
                );
            },
        },
    ];



    const onChange = async (e) => {
        let catData = data;
        catData = catData.filter((v) =>
            v.Server.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setDisplayedData(catData);
    };

    const loadData = async () => {
        try {
            setLoding(true);
            let response = await Kamas.getAllKamas();
            setData(response);
            setDisplayedData(response);
            setLoding(false);
        } catch (e) {
        }
    }

    useEffect(() => {

        loadData();
        // const unsubscribe = Category.getAllCategories(callBack);
        // return () => unsubscribe();
    }, []);

    return (
        <div className="w-full h-full   p-7 " key="table">
            {open && (
                <SuppDialog
                    open={open}
                    setIsOpen={setIsOpen}
                    setRow={setSelectedKamas}
                    onConfirme={async () => {
                        try {
                            await Kamas.deletekamas(selectedKamas.id, token);
                            await loadData();
                            showAlert('Kamas supprimé avec succes');
                            setIsOpen(false);
                            setSelectedKamas(undefined);
                        } catch (e) {

                        }
                    }}
                />
            )}
            {showFormular && (
                <KamasForm
                    setShowFormular={setShowFormular}
                    isConsulting={isConsulting}
                    data={selectedKamas}
                    setSelectedCat={setSelectedKamas}
                    loadData={loadData}
                />
            )}
            <div className="h-full w-full p-7 ">
                <div className="bg-white h-full w-full p-5 flex flex-col rounded-3xl">
                    <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                        <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                            Kamas
                        </h1>

                        <div className="flex lg:w-1/3 w-full py-2">
                            <input
                                onChange={onChange}
                                className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                                id="inline-searcg"
                                type="text"
                                placeholder="Recherche"
                            />
                        </div>
                        <div className="flex items-center py-2">
                            <button
                                onClick={() => setShowFormular(true)}
                                className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
                            >
                                Ajouter de Kamas
                                <img
                                    src={plusIcon}
                                    alt="add"
                                    className="lg:w-10 lg:h-10 w-5 ml-4"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b  ">
                            {loding ? (
                                <h1 className="text-xl h-96 flex items-center justify-center">
                                    <ComponentLoader />
                                </h1>
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={displayedData}
                                    pagination
                                    customStyles={customStyles}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KamasTable;
