import React from "react";
import CommandeTable from "../../components/CommandeTable";




const CommandeDashboard = () => {
    return (<div className="w-full h-full">
        <CommandeTable />
    </div>)
}

export default CommandeDashboard;