import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from '../assets/logo.png'
import AuthContext from "../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import instance from "../api/axiosConfig";
import background from "../assets/dofusLogo.jpg"
const RegisterPage = () => {

    const [error, setError] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const validation = useFormik({
        //enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            lastName: '',
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Veuillez entrer votre Nom."),
            lastName: Yup.string().required("Veuillez entrer votre Prènom."),
            email: Yup.string().email().required("Veuillez entrer votre adresse e-mail."),
            password: Yup.string().required("Veuillez entrer votre mot de passe."),
        }),
        onSubmit: async (values) => {
            setError(false);
            try {
                let response = await instance.post('/api/auth/register', {
                    name: values.name, lastName: values.lastName, email: values.email, password: values.password, role: 'USER'
                })
                let userRole = await login(values.email, values.password);
                if (userRole == 'ADMIN') {
                    navigate('/admin');
                } else {
                    navigate('/');
                }
            } catch (e) {
                setError(true);
            }

            // signInWithEmailAndPassword(auth, values.email, values.password)
            //     .then((userCredential) => {
            //         const user = userCredential.user;
            //         //navigate("/")
            //     })
            //     .catch((error) => {
            //         //setError(true)
            //     });

        }
    });
    return (
        <div className="w-full h-full flex flex-row">
            <div className="w-1/2 h-full bg-gray-700 flex flex-col justify-center items-center">
                <img src={background} className="w-full h-full object-cover" />
            </div>
            <div className="w-1/2 bg-white flex flex-row justify-center items-center space-x-3 px-20">
                <div>
                    <div className="pb-5 flex flex-col items-center gap-5">
                        <img className="w-5/12" src={logo} onClick={() =>{navigate('/')}}/>
                        <h1 className="text-xl font-bold text-gray-700 text-center ">Créez votre compte ProKamas en quelques étapes simples
 et commencez à explorer nos services !</h1>
                    </div>

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }} action="#">
                        <div className="mt-8 content-center">
                            <label className="ml-3 text-sm font-bold text-gray-700 tracking-wide">
                                Nom
                            </label>
                            <input
                                id="name"
                                name="name"
                                type="name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                autoFocus
                                className="w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 focus:outline-none focus:border-indigo-500"
                                placeholder="Saisissez votre nom" />
                            {validation.touched.name && validation.errors.name ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.name}</h1>
                            ) : null}
                        </div>
                        <div className="mt-4 content-center">
                            <label className="ml-3 text-sm font-bold text-gray-700 tracking-wide">
                                Prénom
                            </label>
                            <input
                                id="lastName"
                                name="lastName"
                                type="lastName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}

                                className="w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 focus:outline-none focus:border-indigo-500"
                                placeholder="Saisissez votre prénom" />
                            {validation.touched.lastName && validation.errors.lastName ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.lastName}</h1>
                            ) : null}
                        </div>
                        <div className="mt-4 relative">
                            <label className="ml-3 text-sm font-bold text-gray-700 tracking-wide">Adresse e-mail</label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-2xl focus:border-indigo-500"
                                placeholder="Saisissez votre adresse e-mail" />
                            {validation.touched.email && validation.errors.email ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.email}</h1>
                            ) : null}
                        </div>
                        <div className="mt-8 content-center">
                            <label className="ml-3 text-sm font-bold text-gray-700 tracking-wide">
                                Mot de passe
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}

                                className="w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 focus:outline-none focus:border-indigo-500"
                                placeholder="Saisissez votre mot de passe" />
                            {validation.touched.password && validation.errors.password ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.password}</h1>
                            ) : null}
                        </div>
                        <h1 className={`mb-5 text-red-500 ${error ? 'visible' : 'hidden'}`}>Problem dans la creation du compte</h1>
                        <div className='mt-8'>
                            <button type="submit"
                                className="w-full flex justify-center bg-gradient-to-r bg-main p-4 rounded-xl tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500 text-white">
                                Créer un compte
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default RegisterPage;