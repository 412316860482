import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import AuthContext from "../context/AuthContext/AuthContext";
import { baseURL } from "../api/axiosConfig";
import Sellingorder from "../models/Sellingorder";
import ComponentLoader from "./ComponentLoader";
import ExchnageOrder from "../models/exchangeOrder";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import AlertContext from "../context/AlertContext/allertContext";
import ExchnageForm from "./exchnageForm";
import SuppDialog from "./suppDialog";

const ExchangeTable = () => {

    const { token } = useContext(AuthContext);

    const [data, setData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);

    const { showAlert } = useContext(AlertContext);
    const [loding, setLoding] = useState(true);
    const [open, setIsOpen] = useState(false);
    const [showFormular, setShowFormular] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(undefined);

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "white",
                backgroundColor: "#1F2937",
            },
        },

    };

    const [loading, setloading] = useState(true);

    const loadData = async () => {
        setloading(true);
        let response = await ExchnageOrder.getAllOrder(token);
        console.log(response)
        setDisplayedData(response);
        setData(response);
        setloading(false);
    }

    useEffect(() => {

        loadData();
    }, []);

    const columns = [
        // {
        //     name: (
        //         <span className=" text-lg whitespace-nowrap font-semibold mx-auto">

        //         </span>
        //     ),
        //     sortable: false,

        //     cell: (row) => {
        //         return (
        //             <div className="p-2 rounded-full">
        //                 <img
        //                     src={`${baseURL}/api/file/${row.Kama.Server.image}`}
        //                     alt="edit"
        //                     className="h-16 w-16 object-contain"
        //                 />
        //             </div>
        //         );
        //     },
        // },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Du serveur</span>
            ),
            selector: (row) =>
                row.FromServer.name,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Vers serveur</span>
            ),
            selector: (row) =>
                row.ToServer.name,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Code Commande</span>
            ),
            selector: (row) =>
                row.code,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Nom personnage</span>
            ),
            selector: (row) =>
                row.namePerso,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Quantite</span>
            ),
            selector: (row) =>
                row.qte + " M",
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Status</span>
            ),
            cell: (row) =>
                <h1 className="w-60">{row.status.replaceAll('_', ' ')}</h1>,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Total</span>
            ),
            cell: (row) =>
                <h1 className="w-60">{row.totale} M</h1>,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold mx-auto">
                    Actions
                </span>
            ),
            sortable: false,

            cell: (row) => {
                return (
                    <div className="flex mx-auto flex-row gap-4">
                        <img
                            src={editIcon}
                            alt="edit"
                            className=" cursor-pointer"
                            onClick={() => {
                                setSelectedOrder(row);
                                setShowFormular(true);
                            }}
                        />

                        <img
                            src={deleteIcon}
                            alt="delete"
                            className="cursor-pointer"
                            onClick={() => {
                                setIsOpen(true);
                                setSelectedOrder(row);
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const onChange = async (e) => {
        let catData = data;
        catData = catData.filter((v) =>
            v.code.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setDisplayedData(catData);
    };


    return (
        <div className="w-full h-full   p-7 " key="table">
            {open && (
                <SuppDialog
                    open={open}
                    setIsOpen={setIsOpen}
                    setRow={setSelectedOrder}
                    onConfirme={async () => {
                        try {
                            // await Kamas.deletekamas(selectedKamas.id, token);
                            // await loadData();
                            showAlert('Kamas supprimé avec succes');
                            setIsOpen(false);
                            setSelectedOrder(undefined);
                        } catch (e) {

                        }
                    }}
                />
            )}
            {showFormular && (
                < ExchnageForm
                    setShowFormular={setShowFormular}
                    data={selectedOrder}
                    setSelected={setSelectedOrder}
                    loadData={loadData}
                />
            )}
            <div className="h-full w-full p-7 ">
                <div className="bg-white h-full w-full p-5 flex flex-col rounded-3xl">
                    <div className="mb-4 flex lg:flex-row flex-col items-center">
                        <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                            Demande d'èchange
                        </h1>

                        <div className="flex lg:w-1/3 w-full py-2 ml-36">
                            <input
                                onChange={onChange}
                                className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                                id="inline-searcg"
                                type="text"
                                placeholder="Recherche"
                            />
                        </div>
                        {/* <div className="flex items-center py-2">
                        <button
                            onClick={() => setShowFormular(true)}
                            className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
                        >
                            Ajouter de Kamas
                            <img
                                src={plusIcon}
                                alt="add"
                                className="lg:w-10 lg:h-10 w-5 ml-4"
                            />
                        </button>
                    </div> */}
                    </div>
                    <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b  ">
                            {loading ? (
                                <h1 className="text-xl h-96 flex items-center justify-center">
                                    <ComponentLoader />
                                </h1>
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={displayedData}
                                    pagination
                                    customStyles={customStyles}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExchangeTable;