import React, { useEffect, useRef, useState, useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import AlertContext from '../context/AlertContext/allertContext';
import { Progress } from "@material-tailwind/react";
import ReactSelect from 'react-select';
import AuthContext from '../context/AuthContext/AuthContext'
import Serveres from '../models/Serveres'

import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import instance from "../api/axiosConfig";


// Register the plugins
registerPlugin(FilePondPluginImagePreview);



const ServerForm = ({ setShowFormular, data, setSelectedServer, loadData }) => {
    const { showAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState(false);
    const error = '';

    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [showFileRequiredError, setShowFileRequiredError] = useState(false);

    const [progress, setProgress] = useState(0);

    const { token } = useContext(AuthContext);

    const list = [
        { value: 'classic', label: 'Classic', },
        { value: 'retro', label: 'Retro', },
        { value: 'touch', label: 'Touch', },
        { value: 'wakfu', label: 'Wakfu', },
        { value: 'temporis', label: 'Temporis', },
    ];

    const [selectedCat, setSelectedCat] = useState([]);
    const [catError, setCatError] = useState(false);

    const filePondRef = useRef(null);

    const uploadFile = async (file, onUploadProgress) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const response = await instance.post('/api/file', { image: reader.result }, {
                        onUploadProgress: onUploadProgress,
                    });
                    resolve(response.data.newFileName);
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };

    const getFile = async (imageName, onDownloadProgress) => {
        try {
            const response = await instance.get(`/api/file/${imageName}`, {
                responseType: 'blob',
                onDownloadProgress: onDownloadProgress,
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

    const deleteFile = async (fileName) => {
        try {
            await instance.delete(`/api/file/${fileName}`);
            //console.log('File deleted successfully!');
        } catch (error) {
            // console.error('Error deleting file:', error);
            throw error;
        }
    };
    useEffect(() => {
        const filePond = filePondRef.current;
        const fetchImages = async (img) => {
            if (img === "") return;
            try {
                const response = await getFile(img);
                const image = response.data;
                const file = new File([image], img, { type: "image/jpeg" });
                setFiles([file]);
            } catch (error) { }
        };
        if (data !== undefined) {
            filePond?.removeFiles();
            fetchImages(data.image);
            setSelectedCat({ value: data.category, label: data.category })
        }
    }, []);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: data ? data.name : '',
            min_kamas_sell: data ? data.min_kamas_sell : 0,
            min_kamas_buy: data ? data.min_kamas_buy : 0,
            min_kamas_exchange: data ? data.min_kamas_exchange : 0
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Enter server name"),
            min_kamas_sell: Yup.number().positive('ce nombre ne peut pas etre null').required("Entrez le nombre minimale des kamas a vender"),
            min_kamas_buy: Yup.number().positive('ce nombre ne peut pas etre null').required("Entrez le nombre minimale des kamas a acheter"),
            min_kamas_exchange: Yup.number().positive('ce nombre ne peut pas etre null').required("Entrez le nombre minimale des kamas a echanger")
            // selling_price_USDT: Yup.number().positive().required("Entrez le prix de vente Skrill")
        }),
        onSubmit: async (values) => {
            if (loading == false) {
                setLoading(true);
                if (data == undefined) {
                    if (selectedCat.length == 0) {
                        setCatError(true);
                    } else {
                        setCatError(false);
                        try {
                            let image = await uploadFile(files[0], (progressEvent) => {
                                const progressPercentage = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                );
                                setProgress(progressPercentage);
                            });
                            await Serveres.create({
                                name: values.name,
                                min_kamas_sell: values.min_kamas_sell,
                                min_kamas_buy: values.min_kamas_buy,
                                min_kamas_exchange: values.min_kamas_exchange,
                                category: selectedCat.value,
                                image: image,
                            }, token);
                            await loadData();
                            showAlert('Serveur crée avec succès');
                            setShowFormular(false);
                        } catch (e) {
                            setLoading(false);
                        }
                    }
                } else {
                    try {
                        let imageName = data.image;
                        if (files[0].file.name != data.image) {
                            try {
                                await deleteFile(data.image);
                            } catch (e) { }
                            imageName = await uploadFile(files[0], (progressEvent) => {
                                const progressPercentage = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                );
                                setProgress(progressPercentage);
                            });
                        }
                        await Serveres.update(data.id, {
                            name: values.name,
                            min_kamas_sell: values.min_kamas_sell,
                            min_kamas_buy: values.min_kamas_buy,
                            min_kamas_exchange: values.min_kamas_exchange,
                            category: selectedCat.value,
                            image: imageName,
                        }, token);
                        await loadData();
                        showAlert('Serveur crée avec succès');
                        setShowFormular(false);
                    } catch (e) {
                    }

                }

            }
            setLoading(false);
        }
    });

    return (<form onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
    }} action="#">
        <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 ">
            <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        {data != undefined && <>
                            <label className='block' htmlFor="id">id</label>
                            <h1 className="w-full bg-gray-100 p-2 mt-2 mb-3 text-gray-400">{data.id}</h1>
                        </>}
                        <div>
                            <label className='text-gray-800 block mb-2 font-bold ' htmlFor="name">nom</label>
                            <input
                                name="name"
                                className="w-full bg-gray-100 p-2 mt-2 mb-3"
                                placeholder="Enter le nom"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.name}</h1>
                            ) : null}
                        </div>

                        <div>
                            <label className='text-gray-800 block mb-2 font-bold ' htmlFor="server">Server Category</label>
                            <ReactSelect
                                className="mb-2"
                                value={selectedCat}
                                isMulti={false}
                                options={list}
                                onChange={(val) => {
                                    setSelectedCat(val);
                                }}
                                menuShouldScrollIntoView={true}
                                maxMenuHeight={200}
                            // options={dissortbyMulti}
                            // classNamePrefix="js-example-disabled-multi mb-0"
                            // isDisabled={fa}
                            />
                            {catError && <h1 className="text-red-500 font-semibold">Choisit une categories</h1>}
                        </div>

                        <div>
                            <label className='text-gray-800 block mb-2 font-bold ' htmlFor="name">Nombre minimale de Kamas achat</label>
                            <input
                                name="min_kamas_buy"
                                className="w-full bg-gray-100 p-2 mt-2"
                                placeholder="Enter le nom"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.min_kamas_buy || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.min_kamas_buy && validation.errors.min_kamas_buy ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.min_kamas_buy}</h1>
                            ) : null}
                        </div>

                        <div>
                            <label className='text-gray-800 block mb-2 font-bold ' htmlFor="name">Nombre minimale de Kamas vente</label>
                            <input
                                name="min_kamas_sell"
                                className="w-full bg-gray-100 p-2 mt-2"
                                placeholder="Enter le nom"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.min_kamas_sell || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.min_kamas_sell && validation.errors.min_kamas_sell ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.min_kamas_sell}</h1>
                            ) : null}
                        </div>

                        <div>
                            <label className='text-gray-800 block mb-2 font-bold ' htmlFor="name">Nombre minimale de Kamas echange</label>
                            <input
                                name="min_kamas_exchange"
                                className="w-full bg-gray-100 p-2 mt-2"
                                placeholder="Enter le nom"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.min_kamas_exchange || ""}
                            // invalid={
                            //     validation.touched.name && validation.errors.name ? true : false
                            // }
                            />
                            {validation.touched.min_kamas_exchange && validation.errors.min_kamas_exchange ? (
                                <h1 className='text-red-500 font-semibold'>{validation.errors.min_kamas_exchange}</h1>
                            ) : null}
                        </div>

                        <div className="w-full">
                            <label
                                className="text-gray-800 block mb-2 font-bold mt-3"
                                htmlFor="name"
                            >
                                image
                            </label>
                            <FilePond
                                ref={filePondRef}
                                files={files}
                                onupdatefiles={setFiles}
                                allowMultiple={false}
                                name="files"
                                className="bg-white p-10"
                                allowFileTypeValidation={true}
                                acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                            />
                            {showFileRequiredError ? (
                                <p className="text-red-500 text-xs">
                                    Aucune image sélectionnée. Veuillez choisir une image
                                    avant de continuer.
                                </p>
                            ) : null}
                        </div>

                    </div>
                    <div className="w-full">
                        <Progress className='h-2' color='amber' value={progress} variant="filled" />
                    </div>
                    <div className="bg-gray-200 px-4 py-3 text-right">
                        <button onClick={() => { setSelectedServer(undefined); setShowFormular(false); }} type="button" className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2">Annuler</button>
                        <button color="success" disabled={error ? null : loading ? true : false} className="py-2 px-4 bg-amber-500 text-white rounded hover:bg-amber-600 mr-2" type="submit">
                            {error ? null : loading ? <h1 className='text-sm me-2'> Loading... </h1> : data != undefined ? 'Mettre à jour' : 'Créer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form >)
}

export default ServerForm;