import React, { useContext, useEffect, useState } from "react";
import AppBare from "../components/appBare";
import Lottie from "lottie-react";
import logo from '../assets/120311-game.json'
import ReactSelect from 'react-select';
import Serveres from "../models/Serveres";
import ExchnageOrder from "../models/exchangeOrder";
import AuthContext from "../context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import background from "../assets/dofusLogo.jpg"
// import { useNavigation } from "react-router-dom";

const ExchangeKamsPage = () => {

    const [listServer, setlistServer] = useState([]);
    const [listFromServer, setlistFromServer] = useState([]);
    const [listToServer, setlistToServer] = useState([]);
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        const loadData = async () => {
            try{
                let response = await Serveres.getAllServers();
                let data = response.map(v => {
                    return {
                        value: v.Kama.id,
                        id: v.id,
                        label: v.name,
                        priceVente: parseFloat(v.Kama.buying_price_Skrill),
                        prixAchat: parseFloat(v.Kama.selling_price_Skrill),
                        min_kamas_exchange: parseFloat(v.min_kamas_exchange)
                    }
                })
                setlistServer(data);
                setlistToServer(data);
                setlistFromServer(data);
            }catch{

            }
        }
        loadData();
    }, [])

    const [selectedServer, setSelectedServer] = useState();
    const [selectedTOServer, setSelectedTOServer] = useState();
    const [pesoName, setPersoName] = useState('');

    const navigate = useNavigate();

    const [serverErrror, setServerErrror] = useState(false);
    const [serverToErrror, setServerToErrror] = useState(false);
    const [qteError, setQTEError] = useState(false);
    const [persoError, setPersoError] = useState(false);

    const { token, userInfo } = useContext(AuthContext);

    const onChangeFromServer = (server) => {
        setCounter(server.min_kamas_exchange);
        setSelectedServer(server);
        setlistToServer(listServer.filter((s)=> s.id != server.id));
    }

    const onChangeToServer = (server) => {
        setlistFromServer(listServer.filter((s)=> s.id != server.id));
        setSelectedTOServer(server);
    }

    return (<div className="w-full h-full flex flex-col overflow-auto">
        <AppBare showLogo={true} />
        <div className="flex-1 flex flex-row px-10 pb-10 z-20 mt-28 gap-10">
            <div className="w-1/2 h-full flex flex-col items-center rounded-xl gap-5">
                <div className="border-main border-2 rounded-xl p-5 bg-white bg-opacity-50 backdrop-blur-sm flex flex-col items-center text-lg ">
                    <h1 className="text-xl text-black font-bold ">
                        Echange kamas en 4 Étapes :
                    </h1>
                    <h1>
                        <span className="font-bold">1-Commande Facile : </span>
                        Sélectionnez le serveur où vous avez les kamas, la quantité que vous souhaitez échanger, le serveur où vous souhaitez recevoir les kamas et le nom de votre personnage dans le jeu. Vous verrez en bas le montant que vous recevrez sur le serveur souhaité une fois votre demande confirmée.
                    </h1>
                    <h1>
                        <span className="font-bold">2-Numéro de Commande : </span>
                        Une fois la commande passée, vous serez redirigé vers la page de vos commandes passées où vous trouverez votre numéro de commande unique.
                    </h1>
                    <h1>
                        <span className="font-bold">3-Contactez-nous : </span>
                        Pour finaliser votre échange ou pour toute question, cliquez sur l'icône en bas à droite de votre écran pour contacter notre équipe de support en incluant votre numéro de commande.
                    </h1>
                    <h1>
                        <span className="font-bold">4-Traitement Rapide : </span>
                        Notre équipe vous assistera rapidement pour organiser l'échange de vos kamas entre serveurs.
                    </h1>
                </div>
            </div>
            <div className="w-1/2 h-full flex flex-col justify-between bg-gray-500 p-10 rounded-xl bg-opacity-20 backdrop-blur-sm">
                <div className="w-full">
                    <label className='block font-bold text-xl' htmlFor="name">Serveur :</label>
                    <ReactSelect
                        className=""
                        value={selectedServer}
                        isMulti={false}
                        options={listFromServer}
                        onChange={(val) => onChangeFromServer(val)}
                        menuShouldScrollIntoView={true}
                        maxMenuHeight={200}
                    // options={dissortbyMulti}
                    // classNamePrefix="js-example-disabled-multi mb-0"
                    // isDisabled={fa}
                    />
                    {serverErrror && <h1 className="text-red-500">Sélectionner un serveur</h1>}
                </div>
                <div className="w-full pt-4">
                    <h1 className="pr-5 text-lg pb-4 text-main ">La quantité minimale de kamas à échanger est :  
                        <span className="text-lg font-bold text-main ">{selectedServer == undefined ? 10 : selectedServer.min_kamas_exchange} M</span>
                    </h1>
                    <label className='block font-bold text-xl' htmlFor="name"> Nombre de Kamas</label>
                    <div className="flex flex-row">
                        <div className="border-2 border-gray-600 rounded-xl flex flex-row font-bold">
                            <button className="px-5 py-3 cursor-pointer" onClick={() => {
                                if (counter > selectedServer.min_kamas_exchange) {
                                    setCounter(counter - 1);
                                }
                            }}>-</button>
                            <input type="text"
                                id="counter-input"
                                data-input-counter
                                class="text-gray-900 border-0 bg-transparent focus:outline-none focus:ring-0 text-center"
                                placeholder=""
                                value={counter}
                                required
                                onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    if (!isNaN(newValue)) {
                                        setCounter(newValue)
                                    }
                                }}
                                onBlur={(e) => {
                                    let min = selectedServer == undefined ? 10 : selectedServer.min_kamas_exchange
                                    const newValue = Number(e.target.value);
                                    if (!isNaN(newValue)) {
                                        if (newValue > min){
                                            setCounter(newValue)
                                        }else{
                                            setCounter(min)
                                        }
                                    }
                                }}
                            />
                            <button className="px-5 py-3 cursor-pointer" onClick={() => {
                                setCounter(counter + 1)
                            }}>+</button>
                        </div>
                    </div>
                    <h1 className="pt-2 text-xl">
                        <span className="font-bold">{counter} </span>
                        M Kamas =
                        <span className="font-bold"> {(counter * 1000000).toLocaleString().replaceAll(',', '.')} </span>
                        Kamas </h1>
                    {qteError && <h1 className="text-red-500">Entrez la quantité de kamas que vous voulez échanger</h1>}
                </div>
                <div className="w-full pt-4">
                    <label className='block font-bold text-xl' htmlFor="name">Serveur souhaité :</label>
                    <ReactSelect
                        className=""
                        value={selectedTOServer}
                        isMulti={false}
                        options={listToServer}
                        onChange={(val) => onChangeToServer(val)}
                        menuShouldScrollIntoView={true}
                        maxMenuHeight={200}
                    // options={dissortbyMulti}
                    // classNamePrefix="js-example-disabled-multi mb-0"
                    // isDisabled={fa}
                    />
                    {serverToErrror && <h1 className="text-red-500">Sélectionner le serveur souhaité</h1>}
                </div>

                <div className="w-full pt-4">
                    <label className='block font-bold text-xl' htmlFor="name">Pseudo dofus </label>
                    <input onChange={(event) => {
                        setPersoName(event.target.value);
                    }} placeholder="Pseudo dofus" className="w-full bg-gray-100 p-2 mt-2" />
                    {persoError && <h1 className="text-red-500">Pseudo dofus</h1>}
                </div>
                <div className="flex-1 w-full flex flex-col justify-end">
                    <label className='block font-bold text-xl' htmlFor="name">Vous aurez sur le serveur souhaité : </label>
                    <div className="flex flex-col py-5 w-full">
                        <h1 className="font-bold text-2xl text-main">{(counter * (selectedServer == undefined ? 0 : selectedServer.prixAchat) / (selectedTOServer == undefined ? 1 : selectedTOServer.priceVente)).toFixed(2)} M Kamas</h1>
                    </div>
                    <button onClick={async () => {
                        if (selectedServer == undefined) {
                            setServerErrror(true);
                        } else {
                            setServerErrror(false);
                            if (counter == '') {
                                setQTEError(true);
                            } else {
                                setQTEError(false);
                                if (selectedTOServer == undefined) {
                                    setServerToErrror(true);
                                } else {
                                    setServerToErrror(false);
                                    if (pesoName == '') {
                                        setPersoError(true);
                                    } else {
                                        setPersoError(false);
                                        await ExchnageOrder.create({
                                            qte: counter,
                                            status: 'EN_ATTENTE_DE_PAIEMENT',
                                            namePerso: pesoName,
                                            formServer: selectedServer.id,
                                            toServer: selectedTOServer.id,
                                            userId: userInfo.id,
                                            totale: `${
                                                (counter * (selectedServer == undefined ? 0 : selectedServer.prixAchat) / (selectedTOServer == undefined ? 1 : selectedTOServer.priceVente)).toFixed(2) 
                                            }`
                                        }, token);
                                        navigate('/mes-commande', { state: { selectedpage: 'echange' } });
                                    }
                                }
                            }
                        }

                    }} className="w-full bg-main text-white text-xl px-10 py-4 rounded-xl font-bold">Passer la commande</button>
                </div>
            </div>
        </div>
        <img className="w-full h-full absolute z-10" src={background} />
    </div >
    );
}

export default ExchangeKamsPage;