import React from "react";
import ServerTable from "../../components/ServerTable";


const ServerDashboard = () => {
    return (<div className="w-full h-full">
        <ServerTable />
    </div>)
}

export default ServerDashboard;