import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import AuthContext from "../context/AuthContext/AuthContext";
import { baseURL } from "../api/axiosConfig";
import Sellingorder from "../models/Sellingorder";
import ComponentLoader from "./ComponentLoader";

const SellingOrderTable = () => {

    const { token, userInfo } = useContext(AuthContext);

    const [data, setData] = useState([]);

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "white",
                backgroundColor: "#1F2937",
            },
        },

    };

    useEffect(() => {
        const loadData = async () => {
            let response = await Sellingorder.getOrderByUser(userInfo.id, token);
            setData(response);
        }

        loadData();
    }, []);

    const columns = [
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold mx-auto">

                </span>
            ),
            sortable: false,

            cell: (row) => {
                return (
                    <div className="p-2 rounded-full">
                        <img
                            src={`${baseURL}/api/file/${row.Kama.Server.image}`}
                            alt="edit"
                            className="h-16 w-16 object-contain"
                        />
                    </div>
                );
            },
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Nom du serveur</span>
            ),
            selector: (row) =>
                row.Kama.Server.name,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Code Commande</span>
            ),
            selector: (row) =>
                row.code,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Nom personnage</span>
            ),
            selector: (row) =>
                row.namePerso,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Quantite</span>
            ),
            selector: (row) =>
                row.qte + ' M',
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Status</span>
            ),
            selector: (row) =>
                row.status.replaceAll('_', ' '),
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Méthode de paiement</span>
            ),
            selector: (row) =>
                row.paiement_method.toUpperCase(),
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Date de création</span>
            ),
            selector: (row) =>
            formatDateToFrench(row.createdAt),
            sortable: true,
        },
    ];

    function formatDateToFrench(isoDate) {
        const date = new Date(isoDate);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    return (
        <div>
            <h1 className="py-5 text-2xl font-bold">Mes Ventes</h1>
            <DataTable
                columns={columns}
                data={data}
                pagination
                customStyles={customStyles}
            />
        </div>
    );
}

export default SellingOrderTable;