import React, { useContext } from "react";
import AlertContext from "../context/AlertContext/allertContext";

const Alert = () => {
    const { msg } = useContext(AlertContext);
    return (
        <div className='p-4 absolute z-50 bg-green-400 top-24 right-10 rounded-sm'>
            <p className="text-gray-100">{msg}</p>
        </div>
    );
}
export default Alert;