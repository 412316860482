import React, { useState, useEffect, useContext } from "react";
import AlertContext from "../context/AlertContext/allertContext";
import AuthContext from "../context/AuthContext/AuthContext";
import ReactSelect from 'react-select';
import ExchnageOrder from "../models/exchangeOrder";



const ExchnageForm = ({ setShowFormular, data, setSelected, loadData }) => {
    const { showAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState(false);
    const error = '';


    const { token } = useContext(AuthContext);

    const list = [
        { value: 'EN_ATTENTE_DE_LIVRAISON', label: 'EN ATTENTE DE LIVRAISON', },
        { value: 'EN_COURS_DE_LIVRAISON', label: 'EN COURS DE LIVRAISON', },
        { value: 'EN_ATTENTE_DE_PAIEMENT', label: 'EN ATTENTE DE PAIEMENT', },
        { value: 'LIVREE', label: 'LIVREE', },
        { value: 'ANNULEE', label: 'ANNULEE', },
        { value: 'TERMINEE', label: 'TERMINEE', },
    ];

    const [selectedStatus, setSelectedStatus] = useState([]);
    const [catError, setCatError] = useState(false);



    useEffect(() => {

    }, []);

    return (
        <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 ">
            <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                        <label className='block' htmlFor="id">id</label>
                        <h1 className="w-full bg-gray-100 p-2 mt-2 mb-3 text-gray-400">{data.id}</h1>

                        <div className="pb-20">
                            <label className='text-gray-800 block mb-2 font-bold ' htmlFor="server">Status</label>
                            <ReactSelect
                                className="mb-2"
                                value={selectedStatus}
                                isMulti={false}
                                options={list}
                                onChange={(val) => {
                                    setSelectedStatus(val);
                                }}
                                menuShouldScrollIntoView={true}
                                maxMenuHeight={150}
                            // options={dissortbyMulti}
                            // classNamePrefix="js-example-disabled-multi mb-0"
                            // isDisabled={fa}
                            />
                            {catError && <h1 className="text-red-500 font-semibold">Choisit une categories</h1>}
                        </div>

                    </div>
                    <div className="bg-gray-200 px-4 py-3 text-right">
                        <button onClick={() => {
                            setSelected(undefined);
                            setShowFormular(false);
                        }} type="button" className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2">Annuler</button>
                        <button onClick={async () => {
                            if (loading == false) {
                                setLoading(true);
                                data.status = selectedStatus.value
                                await ExchnageOrder.update(data.id, data, token);
                                await loadData();
                                setSelected(undefined);
                                setShowFormular(false);
                                setShowFormular(false);
                                showAlert('La commande a été mise à jour.')
                            }
                        }} color="success" disabled={error ? null : loading ? true : false} className="py-2 px-4 bg-amber-500 text-white rounded hover:bg-amber-600 mr-2" type="submit">
                            {error ? null : loading ? <h1 className='text-sm me-2'> Loading... </h1> : data != undefined ? 'Mettre à jour' : 'Créer'}
                        </button>
                    </div>
                </div>
            </div>
        </div>)
}

export default ExchnageForm;