import React, { useContext, useEffect } from "react";
import logo from '../assets/logo.png'
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext/AuthContext";
import { AiOutlineLogout } from "react-icons/ai";
import { BsFillBasket2Fill } from "react-icons/bs";
import avatar from '../assets/test.png'

const AppBare = ({ showLogo,showAuthColor, isHome2 }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const { token, userInfo, logout } = useContext(AuthContext);

    return (
        <div className="px-20 z-50 h-24 py-5 flex flex-row justify-between items-center bg-white bg-opacity-90 drop-shadow-xl fixed top-0 left-0 right-0">
            {showLogo && <img className="w-1/6" src={logo} />}

            <div className={`flex flex-row gap-10 text-xl font-semibold`}>
                <h1 onClick={() => { navigate('/') }} className={`cursor-pointer py-4 px-5 rounded-xl hover:text-main ${currentPath == '/' ? 'bg-main text-white hover:text-red-100 ' : ''}`}>Accueil</h1>
                <h1 onClick={() => { navigate('/acheter-kamas-dofus') }} className={`cursor-pointer py-4 px-5 rounded-xl hover:text-main ${currentPath == '/acheter-kamas-dofus' || currentPath == '/acheter-kamas-dofus' ? 'bg-main text-white hover:text-red-100 ' : ''}`}>Acheter</h1>
                <h1 onClick={() => { navigate('/vendre-kamas-dofus') }} className={`cursor-pointer py-4 px-5 rounded-xl hover:text-main ${currentPath == '/vendre-kamas-dofus' ? 'bg-main text-white hover:text-red-100 ' : ''}`}>Vendre</h1>
                <h1 onClick={() => { navigate("/echange-kamas-dofus") }} className={`cursor-pointer py-4 px-5 rounded-xl hover:text-main  ${currentPath == '/echange-kamas-dofus' ? 'bg-main text-white hover:text-red-100 ' : ''}`}>Echanger</h1>
            </div>
            {showAuthColor && <div className={`${isHome2 ? 'bg-[#79CFA0]' : 'bg-main'} w-1/4  h-full absolute right-0`}></div>}

            {
                token == null ? <div className=" w-1/4 flex flex-row justify-cnter gap-4 z-10 justify-center text-xl">
                    <div onClick={() => { navigate('/se-connecter') }} className={`font-semibold cursor-pointer ${showAuthColor ? 'text-white' : 'text-main'} h-10 px-10 flex items-center rounded-lg`}>Connexion</div>
                    <div onClick={() => navigate('/inscription')} className={`font-semibold cursor-pointer ${showAuthColor ? 'text-white' : 'text-main'} ${showAuthColor ? 'border-white' : 'border-main'} border-2 h-10 px-10 flex items-center rounded-lg`}>Inscription</div>
                </div> :
                <div className="flex items-center space-x-3 cursor-pointer">
                    <div className="relative w-12 h-12 rounded-full overflow-hidden border-2 dark:border-white border-gray-900">
                        <div className="absolute w-2 h-2 rounded-full bg-green-500 top-0 right-0 border-5 border-white"></div>
                        <img src={avatar} alt="" className="w-full h-full object-cover" />
                    </div>
                    <div className="font-semibold text-gray-900 text-lg pr-6">
                        <div className="cursor-pointer">{userInfo.lastName.toUpperCase()} {userInfo.name.toUpperCase()}</div>
                    </div>
                    <div className="pr-3">
                        <BsFillBasket2Fill size={30} color="green" onClick={async () => { navigate('/mes-commande') }} />
                    </div>
                    <AiOutlineLogout size={30} color="red" onClick={async () => { await logout(); }} />
                </div>
            }


        </div >
    );
}

export default AppBare;