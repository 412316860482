import React, { useEffect, useState } from 'react';
import AlertContext from './allertContext';

function AlertProvider({ children }) {
    const [show, setShow] = useState(false);
    const [msg, setMessage] = useState('');
    const showAlert = (msg) => {
        setMessage(msg);
        setShow(true);
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }
    useEffect(() => {
        setMessage('');
    }, []);

    return <AlertContext.Provider value={{ show, showAlert, msg, setMessage }}>{children}</AlertContext.Provider>;
}

export default AlertProvider;