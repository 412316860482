import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import React from "react";

const SuppDialog = ({ open, setIsOpen, setRow, onConfirme }) => {
    return (<div className='absolute bg-gray-900 opacity-70 w-screen h-screen z-50 top-0 left-0'>
        <Dialog
            open={open}
            size={'sm'}
            //handler={handleOpen}
            className='w-96 h-52 place-self-center'
        >
            <DialogHeader>Confirmation de supretion</DialogHeader>
            <DialogBody divider>
                Vous voulez supprimer ?
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    onClick={() => { setIsOpen(false); setRow(undefined); }}
                    className="mr-1 text-red-500 border-red-500"
                >
                    <span>Cancel</span>
                </Button>
                <Button
                    variant="gradient"
                    color="green"
                    className='bg-red-500'
                    onClick={onConfirme}
                >
                    <span>Confirm</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </div>)
}

export default SuppDialog;