import React from "react";
import ExchangeOrderTable from "../../components/exchangeTable";



const ExchnageDachboard = () => {
    return (<div className="w-full h-full">
        <ExchangeOrderTable />
    </div>)
}

export default ExchnageDachboard;