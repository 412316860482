import React, { useContext, useEffect, useState } from "react";
import AppBare from "../components/appBare";
import Banner from "../components/banner";
import serverImage from "../assets/kamas.png"
import { useLocation, Navigate, useNavigate } from "react-router-dom";

import background from "../assets/dofusLogo.jpg"
import Lottie from "lottie-react";

import cihLogo from '../assets/cih.png'
import USDTLogo from '../assets/usdtLogo.png'
import SkrillLogo from '../assets/Skrill.png'
import Order from "../models/Order";
import AuthContext from "../context/AuthContext/AuthContext"
import PriceTable from "../components/priceTable";


const CommandeKamasPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let data = location.state;

    const minKamas = data == undefined ? 10 : data.Server.min_kamas_buy
    const [counter, setCounter] = useState(minKamas);

    const [pesoName, setPersoName] = useState('');



    const [persoError, setPersoError] = useState(false);
    const [qteErrror, setQteErrror] = useState(false);
    const [methodPError, setMethodPErro] = useState(false);


    const [price, setprice] = useState(0);

    const paymentMethode = [{ name: 'cih', logo: cihLogo }, { name: 'Skrill', logo: SkrillLogo }, { name: 'USDT', logo: USDTLogo }];
    const [selectedPMethod, setSelectedPMethod] = useState(null);



    const { token, userInfo } = useContext(AuthContext);

    return (
        <div className="w-full h-full flex flex-col overflow-auto">
            <AppBare showLogo={true} />
            <div className="flex-1 flex flex-row px-10 pb-10 z-20 mt-28 gap-10">
                <div className="w-1/2 h-full flex flex-col items-center rounded-xl gap-5">
                    <PriceTable />
                </div>
                <div className="w-1/2 flex flex-col gap-10">
                    <div className="w-full py-20 flex flex-col justify-between bg-gray-500 p-10 rounded-xl bg-opacity-20 backdrop-blur-sm">
                        <div className="flex flex-col justify-center">
                            <div className="flex flex-row items-center justify-between">
                                <h1 className="text-4xl font-extrabold">{data.Server.name}</h1>
                                <h1 className="font-bold text-main" >{data.Server.category}</h1>
                            </div>
                            <div className="flex flex-col pt-5 w-full">
                                <h1 className="pr-5 text-lg pb-4 text-main ">La quantité minimale de kamas à commander est : 
                                    <span className="text-lg font-bold text-main ">{minKamas} M</span>
                                </h1>
                                <h1 className="pr-5 text-xl pb-4">Quantite :</h1>
                                <div className="flex flex-row">
                                    <div className="border-2 border-gray-600 rounded-xl flex flex-row font-bold">
                                        <button className="px-5 py-3 cursor-pointer" onClick={() => {
                                            if (counter > minKamas) {
                                                setCounter(counter - 1);
                                            }
                                        }}>-</button>
                                        <input type="text" 
                                            id="counter-input" 
                                            data-input-counter 
                                            class="text-gray-900 border-0 bg-transparent focus:outline-none focus:ring-0 text-center" 
                                            placeholder="" 
                                            value={counter} 
                                            required 
                                            onChange={(e) => {
                                                const newValue = Number(e.target.value);
                                                if (!isNaN(newValue)) {
                                                    setCounter(newValue)
                                                }
                                            }}
                                            onBlur={(e) => {
                                                const newValue = Number(e.target.value);
                                                if (!isNaN(newValue)) {
                                                    if (newValue > minKamas){
                                                        setCounter(newValue)
                                                    }else{
                                                        setCounter(minKamas)
                                                    }
                                                }
                                            }}
                                        />
                                        <button className="px-5 py-3 cursor-pointer" onClick={() => {
                                            setCounter(counter + 1)
                                        }}>+</button>
                                    </div>
                                </div>
                                <h1 className="pt-2 text-xl">
                                    <span className="font-bold">{counter} </span>
                                    M Kamas =
                                    <span className="font-bold"> {(counter * 1000000).toLocaleString().replaceAll(',', '.')} </span>
                                    Kamas </h1>
                                {/* <h1>1M kamas = 1.000.000 kamas</h1> */}
                            </div>

                            <div className="">
                                <h1 className="py-4">Methode de paiement :</h1>
                                <div className={`grid grid-cols-3 gap-4 justify-star ${methodPError ? 'border-red-500 border-2 rounded-xl' : ''}`}>
                                    {
                                        paymentMethode.map(m => <div
                                            onClick={() => {
                                                if (m.name == 'cih') {
                                                    setprice(data.buying_price_CIH);
                                                } else {
                                                    if (m.name == 'Skrill') {
                                                        setprice(data.buying_price_Skrill);
                                                    } else {
                                                        if (m.name == 'USDT') {
                                                            setprice(data.buying_price_USDT);
                                                        }
                                                    }
                                                }
                                                setSelectedPMethod(m.name);
                                            }}
                                            className={`${selectedPMethod == m.name ? 'border-main border-2' : ''} bg-gray-300 px-10 py-4 rounded-xl h-16 flex items-center justify-center`}>
                                            <img className="h-full" src={m.logo} />
                                        </div>)
                                    }
                                </div>
                                <h1 className="text-xl font-bold text-green-300 pt-2 text-center">Livraison des kamas dans les 5 à 10 minutes suivant la réception du paiement.</h1>
                                {methodPError && <h1 className="text-red-500">Sélectionner un moyen de paiement</h1>}
                            </div>

                            <div className="flex flex-col py-3">
                                <h1 className="w-full pr-5 text-xl pb-4">Pseudo dofus :</h1>
                                <input onChange={(event) => {
                                    setPersoName(event.target.value);
                                }} placeholder="Pseudo dofus" className={`w-full rounded-xl py-2 px-4 border-2 border-gray-600 ${persoError ? 'border-red-500' : ''}`} />
                                {persoError && <h1 className="text-red-500">Entrer le Pseudo dofus</h1>}
                            </div>

                            <div className="flex flex-col py-5 w-full">
                                <h1 className="w-1/4 text-xl pb-2">Total :</h1>
                                <h1 className="font-bold text-2xl text-main">{(price * counter).toFixed(2)}
                                    {selectedPMethod == 'cih' ? ' MAD' : selectedPMethod == 'USDT' ? ' USDT' : ' €'}</h1>
                            </div>
                        </div>

                        <button className=" bg-main text-white text-xl px-10 py-4 rounded-xl font-bold"
                            onClick={async () => {
                                if (selectedPMethod == null) {
                                    setMethodPErro(true);
                                } else {
                                    setMethodPErro(false);
                                    if (pesoName == '') {
                                        setPersoError(true);
                                    } else {
                                        setPersoError(false);
                                        await Order.create({
                                            qte: counter,
                                            status: 'EN_ATTENTE_DE_PAIEMENT',
                                            namePerso: pesoName,
                                            paiement_method: selectedPMethod,
                                            kamasId: data.id,
                                            userId: userInfo.id,
                                            // paiementInfo: paiementInfo,
                                            totale: (price * counter),
                                            sereverName: data.Server.name
                                        }, token);
                                        navigate('/mes-commande', { state: { selectedpage: 'achat' } });
                                    }
                                }
                            }}
                        >Passer la commande</button>
                    </div>
                    <div className="border-main border-2 rounded-xl p-5 bg-white bg-opacity-50 flex flex-col items-center text-lg backdrop-blur-sm">
                        <h1 className="text-xl text-black font-bold ">
                            Livraison kamas en 4 Étapes :
                        </h1>
                        <h1>
                            <span className="font-bold">1-Commande Facile : </span>
                             Sélectionnez votre serveur, la quantité de kamas et votre moyen de paiement, puis cliquez sur "Passer la Commande".
                        </h1>
                        <h1>
                            <span className="font-bold">2-Numéro de Commande : </span>
                             Une fois la commande passée, vous serez redirigé vers la page des commandes passées où vous trouverez votre numéro de commande unique.
                        </h1>
                        <h1>
                            <span className="font-bold">3-Contactez-nous : </span>
                            Pour nous contacter, cliquez sur le bouton en bas à droite de votre écran pour ouvrir une conversation avec notre équipe de support.
                        </h1>
                        <h1>
                            <span className="font-bold">4-Livraison Rapide : </span>
                            Nous vous contacterons rapidement pour finaliser votre commande et organiser la livraison de vos kamas.
                        </h1>
                    </div>
                </div>
            </div>
            <img className="w-full h-full bg-red-500 absolute z-10" src={background}/>
        </div >
    );
}

export default CommandeKamasPage;