import instance from "../api/axiosConfig";

class Kamas {
    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.rate_of_change = data.rate_of_change;
        this.quality_in_stock = data.quality_in_stock;
        this.buying_price = data.buying_price;
        this.selling_price = data.selling_price;
        this.serverId = data.serverId;
        this.buying_price_USDT = data.buying_price_USDT;
        this.buying_price_CIH = data.buying_price_CIH;
        this.buying_price_Skrill = data.buying_price_Skrill;
        this.selling_price_CIH = data.selling_price_CIH;
        this.selling_price_USDT = data.selling_price_USDT;
        this.selling_price_Skrill = data.selling_price_Skrill;
        this.selling_price_Paypal = data.selling_price_Paypal;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }

    static async create(data, token) {
        try {
            // const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTY4OTcwMDkzMSwiZXhwIjoxNjg5NzA0NTMxfQ.G8Fr-nMbb3X4yWKNk74Ln7v3Vlj8rmhKQNhTt-b8NOs' }
            let response = await instance.post('/api/kamas', data, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            //return response.data;
        } catch (e) {
            throw e;
        }

    }
    static async getAllKamas() {
        try {
            let response = await instance.get('/api/kamas');
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async getKamasOfServer(serverCat) {
        try {
            let response = await instance.get(`/api/kamas/kamasByServer/${serverCat}`);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async deletekamas(id, token) {
        try {
            let response = await instance.delete(`/api/kamas/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async update(id, data, token) {
        try {
            let response = await instance.put(`/api/kamas/${id}`, data, { headers: { 'Authorization': `Bearer ${token}` } });
            return response.data;
        } catch (e) {
            throw e;
        }
    }
}

export default Kamas;