import React, { useState, useEffect, useContext } from "react";
import KamasForm from "./KamasForm";
import AlertContext from "../context/AlertContext/allertContext";
import { baseURL } from "../api/axiosConfig";
import SuppDialog from "./suppDialog";

// import eyeIcon from "../assets/icons/show.svg";
import eyeIcon from "../assets/icons/white_show.svg";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import plusIcon from "../assets/icons/plus.svg";

import Kamas from "../models/kamas"
import ComponentLoader from "./ComponentLoader";
import DataTable from "react-data-table-component";
import ServerForm from "./ServersForm";
import Serveres from "../models/Serveres";
import AuthContext from "../context/AuthContext/AuthContext";

const ServerTable = () => {

    const [showFormular, setShowFormular] = useState(false);
    const [isConsulting, setIsConsulting] = useState(false);

    const [selectedServer, setSelectedServer] = useState(undefined);

    const [data, setData] = useState([]);

    const [displayedData, setDisplayedData] = useState([]);

    const { showAlert } = useContext(AlertContext);

    const [loding, setLoding] = useState(true);

    const [open, setIsOpen] = useState(false);

    const { token } = useContext(AuthContext);

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "white",
                backgroundColor: "#1F2937",
            },
        },
    };

    const columns = [
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">ID</span>
            ),
            selector: (row) =>
                row.id,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Nom</span>
            ),
            selector: (row) =>
                row.name,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Categorie</span>
            ),
            selector: (row) =>
                row.category,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Min Kamas</span>
            ),
            selector: (row) =>
                row.min_kamas,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold mx-auto">
                    Actions
                </span>
            ),
            sortable: false,

            cell: (row) => {
                return (
                    <div className="flex mx-auto flex-row gap-4">
                        <img
                            src={editIcon}
                            alt="edit"
                            className=" cursor-pointer"
                            onClick={() => {
                                setSelectedServer(row);
                                setShowFormular(true);
                            }}
                        />

                        <img
                            src={deleteIcon}
                            alt="delete"
                            className="cursor-pointer"
                            onClick={() => {
                                setIsOpen(true);
                                setSelectedServer(row);
                            }}
                        />
                    </div>
                );
            },
        },
    ];



    const onChange = async (e) => {
        // let catData = data;
        // catData = catData.filter((v) =>
        //     v.name.toLowerCase().includes(e.target.value.toLowerCase())
        // );
        // setDisplayedData(catData);
    };

    const loadData = async () => {
        try {
            setLoding(true);
            let response = await Serveres.getAllServers(false);
            setData(response);
            setDisplayedData(response);
            setLoding(false);
        } catch (e) {
        }
    }

    useEffect(() => {

        loadData();

        // const unsubscribe = Category.getAllCategories(callBack);
        // return () => unsubscribe();
    }, []);

    return (
        <div className="w-full h-full   p-7 " key="table">
            {open && (
                <SuppDialog
                    open={open}
                    setIsOpen={setIsOpen}
                    setRow={setSelectedServer}
                    onConfirme={async () => {
                        try {
                            Serveres.delete(selectedServer.id, token)
                            await loadData();
                            showAlert('Serveur supprimé avec succes');
                            setIsOpen(false);
                        } catch (e) {

                        }
                    }}
                />
            )}
            {showFormular && (
                <ServerForm
                    setShowFormular={setShowFormular}
                    isConsulting={isConsulting}
                    data={selectedServer}
                    setSelectedServer={setSelectedServer}
                    loadData={loadData}
                />
            )}

            <div className="bg-white max-w-full    p-7  rounded-3xl  ">
                <div className="flex flex-col">
                    <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                        <div className="mb-4">
                            <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                                Thèmes
                            </h1>
                        </div>{" "}
                        <div className="flex lg:w-1/3 w-full py-2">
                            <input
                                onChange={onChange}
                                className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                                id="inline-searcg"
                                type="text"
                                placeholder="Recherche"
                            />
                        </div>
                        <div className="flex items-center py-2">
                            <button
                                onClick={() => setShowFormular(true)}
                                className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
                            >
                                Créer un Serveur
                                <div className="bg-white py-2 px-2 text-amber-500 rounded-full text-center items-center text-lg ml-3">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M11.2111 0C13.8454 0 15.6154 1.84915 15.6154 4.60109V11.0143C15.6154 13.7662 13.8454 15.6154 11.2111 15.6154H4.40426C1.76999 15.6154 0 13.7662 0 11.0143V4.60109C0 1.84915 1.76999 0 4.40426 0H11.2111ZM11.2111 1.08945H4.40426C2.39097 1.08945 1.08945 2.46723 1.08945 4.60109V11.0143C1.08945 13.1482 2.39097 14.5259 4.40426 14.5259H11.2111C13.2251 14.5259 14.5259 13.1482 14.5259 11.0143V4.60109C14.5259 2.46723 13.2251 1.08945 11.2111 1.08945ZM7.80769 4.5955C8.10838 4.5955 8.35242 4.83953 8.35242 5.14022V7.25571L10.4707 7.25585C10.7713 7.25585 11.0154 7.49989 11.0154 7.80057C11.0154 8.10126 10.7713 8.3453 10.4707 8.3453L8.35242 8.34515V10.4618C8.35242 10.7625 8.10838 11.0065 7.80769 11.0065C7.50701 11.0065 7.26297 10.7625 7.26297 10.4618V8.34515L5.14472 8.3453C4.84331 8.3453 4.6 8.10126 4.6 7.80057C4.6 7.49989 4.84331 7.25585 5.14472 7.25585L7.26297 7.25571V5.14022C7.26297 4.83953 7.50701 4.5955 7.80769 4.5955Z"
                                            fill="#FEBD2A"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>

                    {loding ? (
                        <h1 className="text-xl h-96 flex items-center justify-center">
                            loding...
                        </h1>
                    ) : (
                        <ul className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 grid 2xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4">
                            {/* My code */}
                            {displayedData.map((cat) => (

                                <li className=" w-full aspect-square rounded-xl overflow-hidden text-center">
                                    <img
                                        src={`${baseURL}/api/file/${cat.image}`}
                                        alt={cat.name}
                                        className="w-full  aspect-square object-contain"
                                    />
                                    <div className="w-11/12 bg-gray-800 bg-opacity-40 backdrop-blur-lg py-2 lg:px-4 px-2 relative bottom-14 border border-gray-600 mx-auto rounded-xl flex items-center justify-between">
                                        <span className="text-white text-base capitalize font-semibold truncate ">
                                            {cat.name}
                                        </span>
                                        {/* Actions */}
                                        <div className="flex gap-2 items-center">
                                            <button
                                                className="w-4 h-4"
                                                onClick={() => {
                                                    setSelectedServer(cat);
                                                    setShowFormular(true);
                                                }}
                                            >
                                                <img src={editIcon} alt="edit" />
                                            </button>
                                            <button
                                                className="w-4 h-4"
                                                onClick={() => {
                                                    setIsOpen(true);
                                                    setSelectedServer(cat);
                                                }}
                                            >
                                                <img src={deleteIcon} alt="delete" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            {/* end My code */}
                        </ul>
                    )}
                </div>
            </div>
            {/* <div className="h-full w-full p-7 ">
                <div className="bg-white h-full w-full p-5 flex flex-col rounded-3xl">
                    <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                        <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                            Servers
                        </h1>

                        <div className="flex lg:w-1/3 w-full py-2">
                            <input
                                onChange={onChange}
                                className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                                id="inline-searcg"
                                type="text"
                                placeholder="Recherche"
                            />
                        </div>
                        <div className="flex items-center py-2">
                            <button
                                onClick={() => setShowFormular(true)}
                                className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
                            >
                                Ajouter un Serveur
                                <img
                                    src={plusIcon}
                                    alt="add"
                                    className="lg:w-10 lg:h-10 w-5 ml-4"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b  ">
                            {loding ? (
                                <h1 className="text-xl h-96 flex items-center justify-center">
                                    <ComponentLoader />
                                </h1>
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={displayedData}
                                    pagination
                                    customStyles={customStyles}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default ServerTable;