import { Navigate, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import AuthContext from '../context/AuthContext/AuthContext';

export { PrivateRoute };

function PrivateRoute({ children, isAllowd, redirectTo }) {
    const { userInfo } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('popstate', () => { navigate('/') });

        return () => {
            window.removeEventListener('popstate', () => { navigate('/') });
        };
    }, [userInfo]);

    if (userInfo == null) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/se-connecter" />
    }

    if (!isAllowd) {
        return <Navigate to={redirectTo} />
    }

    // authorized so return child components
    return children;
}