import React from "react";
import VenteTable from "../../components/venteTable";



const SellingOrderDahboard = () => {
    return (<div className="w-full h-full">
        <VenteTable />
    </div>)
}

export default SellingOrderDahboard;