import React, { useContext, useEffect, useState } from "react";
import AppBare from "../components/appBare";
import OrderTable from "../components/orderTable";
import SellingOrderTable from "../components/SellingOrderTable";
import ExchangeOrderTable from "../components/exchnageOderTable";
import { useLocation } from "react-router-dom";
import PopUpInfo from "../components/popUpInfo";

const OrderPage = () => {

    const [selectedPage, setSelectedPage] = useState('achat');
    const location = useLocation();

    useEffect(() => {
        if (location.state != undefined)
            setSelectedPage(location.state.selectedpage)
    }, [])

    return (
        <div className="w-full h-full flex flex-col px-10 pb-10 overflow-auto relative">
            <AppBare />
            <div className="flex flex-row gap-4 py-5 mt-28">
                <div className={`${selectedPage == 'achat' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setSelectedPage('achat');
                    }}>Mes achats</div>
                <div className={`${selectedPage == 'vente' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setSelectedPage('vente');
                    }}>Mes ventes</div>
                <div className={`${selectedPage == 'echange' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setSelectedPage('echange');
                    }}>Mes echanges</div> 
            </div>
            {selectedPage == 'achat' && <OrderTable />}
            {selectedPage == 'vente' && <SellingOrderTable />}
            {selectedPage == 'echange' && <ExchangeOrderTable />}
            {/* <PopUpInfo /> */}
        </div>);
}

export default OrderPage;
