import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Kamas from "../models/kamas"

const PriceTable = ({ isSelling }) => {

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "white",
                backgroundColor: "#1F2937",
            },
        },
    };

    const columnsSelling = [
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Serveur</span>
            ),
            selector: (row) =>
                row.Server.name,
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Statut</span>
            ),
            cell: (row) => {
                return <h1 className={`font-bold ${row.status == 'inStock' ? 'text-red-500' : 'text-green-500'}`}>
                    {row.status == 'inStock' ? 'Complet' : 'Incomplet'}
                </h1>
            },
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">CIH</span>
            ),
            selector: (row) =>
                row.selling_price_CIH + ' MAD/M',
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">USDT</span>
            ),
            selector: (row) =>
                row.selling_price_USDT + ' USDT/M',
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Skrill</span>
            ),
            selector: (row) =>
                row.selling_price_Skrill + ' €/M',
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Paypal</span>
            ),
            selector: (row) =>
                row.selling_price_Paypal + ' €/M',
            sortable: true,
        },
    ];

    const columnsBuying = [
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Serveur</span>
            ),
            selector: (row) =>
                row.Server.name,
            sortable: true,
        },
        // {
        //     name: (
        //         <span className=" text-lg whitespace-nowrap font-semibold">Statut</span>
        //     ),
        //     cell: (row) => {
        //         return <h1 className={`font-bold ${row.status == 'inStock' ? 'text-green-500' : 'text-red-500'}`}>
        //             {row.status != 'inStock' ? 'Complet' : 'Incomplet'}
        //         </h1>
        //     },
        //     sortable: true,
        // },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">CIH</span>
            ),
            selector: (row) =>
                row.buying_price_CIH + ' MAD/M',
            sortable: true,
        },
        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">USDT</span>
            ),
            selector: (row) =>
                row.buying_price_USDT + ' USDT/M',
            sortable: true,
        },


        {
            name: (
                <span className=" text-lg whitespace-nowrap font-semibold">Skrill</span>
            ),
            selector: (row) =>
                row.buying_price_Skrill + ' €/M',
            sortable: true,
        },
    ];

    const [data, setDate] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);

    const onChange = async (e) => {
        let catData = data;
        catData = catData.filter((v) =>
            v.Server.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setDisplayedData(catData);
    };


    useEffect(() => {
        const loadPrices = async () => {
            let response = await Kamas.getAllKamas();
            const groupedByCategory = response.reduce((acc, item) => {
                // If the category doesn't exist in the accumulator, create it
                if (!acc[item.Server.category]) {
                  acc[item.Server.category] = [];
                }
                // Push the item to the category array
                acc[item.Server.category].push(item);
                return acc;
              }, {});
            setDate(groupedByCategory);
            setDisplayedData(groupedByCategory);
        }
        loadPrices();
    }, []);

    return <div className="w-full h-full">
        <div className="flex lg:w-1/2 w-full py-2">
            <input
                onChange={onChange}
                className="bg-white appearance-none border-2 border-red-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                id="inline-searcg"
                type="text"
                placeholder="Recherche"
            />
        </div>
        {
       Object.keys(displayedData).map(key => 
               <div className="pb-4">
                    <h1 className="capitalize text-xl font-bold pb-4">{key}</h1>
                    <DataTable
                    columns={isSelling ? columnsSelling : columnsBuying}
                    data={displayedData[key]}
                    pagination
                    paginationRowsPerPageOptions={[]}
                    paginationPerPage={displayedData[key].count}
                    customStyles={customStyles}
                    />
                </div>
            )
        }
    </div>
}

export default PriceTable;