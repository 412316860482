import './App.css';
import HomePage from './pages/HomePage';
import KamasPricePage from './pages/kamasPricesPage';
import LoginPage from './pages/LoginPage';
import CommandeKamasPage from './pages/CommandkamasPage';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink, redirect } from 'react-router-dom';
import Loader from './pages/loader';
import { useContext, useEffect, useState } from 'react';
import AppBareContext from './context/AppBareContext/AppBareContext';
import SellKamasPage from './pages/SellKamasPage';
import KamasDashboard from './pages/Dashboard/KamasDashboard';
import Dashboard from './pages/Dashboard/Dashboard';
import HomePageDashboard from './pages/Dashboard/Dashboard';
import AuthContext from './context/AuthContext/AuthContext';
import { PrivateRoute } from './routes/privateRoutes';
import ServerDashboard from './pages/Dashboard/ServersDashboard';
import OrderPage from './pages/OrderPage';
import ExchangeKamsPage from './pages/exchangeKamsPage';
import RegisterPage from './pages/RegisterPage';
import CommandeDashboard from './pages/Dashboard/CommandeDashboard';
import SellingOrderDahboard from './pages/Dashboard/SellingOrderDahboard';
import ExchnageDachboard from './pages/Dashboard/exchnageDachboard';

function App() {

  // const user = useContext(AuthContext);

  const [flag, setFlag] = useState(true);
  const { setActiveRoute } = useContext(AppBareContext);
  const { userInfo } = useContext(AuthContext);



  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveRoute(currentPath);
    setTimeout(() => {
      setFlag(false);
    }, 2000)
  }, [userInfo]);

  return (
    <div className='h-screen w-screen'>
      {
        flag ? <Loader /> :
          // <Router>
          <Routes>
            {/* <Route
                path="/"
                element={
                  <PrivateRoute isAllowd={userInfo != null && userInfo.role == 'user'} redirectTo='/admin'>
                    <HomePage />
                  </PrivateRoute>
                }
              /> */}
            <Route
              path="/"
              element={
                <HomePage />
              }
            />
            <Route
              path="/acheter-kamas-dofus"
              element={
                <KamasPricePage />
              }
            />
            {console.log(userInfo)}
            <Route
              path="/vendre-kamas-dofus"
              element={
                <SellKamasPage />
              }
            />
            <Route
              path="/echange-kamas-dofus"
              element={
                <PrivateRoute isAllowd={userInfo != null && userInfo.role == 'USER'} redirectTo='/'>
                  <ExchangeKamsPage />
                </PrivateRoute>
              }
            />
            {/* <Route
                path="/exchnage"
                element={
                  <PrivateRoute isAllowd={userInfo != null && userInfo.role == 'USER'} redirectTo='/'>
                    <ExchangeKamsPage />
                  </PrivateRoute>
                }
              /> */}
            <Route
              path="/commande-kamas-dofus"
              element={
                <PrivateRoute isAllowd={userInfo != null && userInfo.role == 'USER'} redirectTo='/'>
                  <CommandeKamasPage />
                </PrivateRoute>
              }
            />

            <Route path='/mes-commande' element={
              <PrivateRoute isAllowd={userInfo != null && userInfo.role == 'USER'} redirectTo='/'>
                <OrderPage />
              </PrivateRoute>
            } />

            <Route
              path="/admin"
              element={
                <PrivateRoute isAllowd={userInfo != null && userInfo.role == 'ADMIN'} redirectTo='/'>
                  <HomePageDashboard />
                </PrivateRoute>
              }
            >
              <Route path='/admin/dash' element={<Dashboard />} />
              <Route path='/admin/kamas' element={<KamasDashboard />} />
              <Route path='/admin/server' element={<ServerDashboard />} />
              <Route path='/admin/command' element={<CommandeDashboard />} />
              <Route path='/admin/vente' element={<SellingOrderDahboard />} />
              <Route path='/admin/exhange' element={<ExchnageDachboard />} />

            </Route>
            <Route path="/se-connecter" element={<LoginPage />} />
            <Route path="/inscription" element={<RegisterPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        // </Router>
      }

      {/* <Routes>
              <Route
                path="/"
                element={user == null ? <HomePageDashboard /> : flag ? <Loader /> : <LoginPage />}
              >
                <Route path='/' element={<Dashboard />} />
                <Route path='/kamas' element={<KamasDashboard />} />
              </Route>
            </Routes> */}
    </div >
  );
}

export default App;
