import React from "react";

const Header = () => {
    return (
        <div className="bg-white px-6 py-2 w-full shadow-md grid grid-cols-2 items-center">
            {/* Recherche */}
            <div>
                <div className="relative my-2 w-2/3">
                    <input
                        type="search"
                        className="bg-purple-white w-full bg-gray-100 rounded-lg border-0 py-2 px-3"
                        placeholder="Recherche"
                    />
                    <div className="absolute top-0 right-0 pin-r pin-t mt-3 mr-4 text-purple-lighter">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.58333 17.5C13.9556 17.5 17.5 13.9556 17.5 9.58332C17.5 5.21107 13.9556 1.66666 9.58333 1.66666C5.21108 1.66666 1.66667 5.21107 1.66667 9.58332C1.66667 13.9556 5.21108 17.5 9.58333 17.5Z" stroke="#1F2937" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.3333 18.3333L16.6667 16.6667" stroke="#1F2937" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
            {/*user info */}
            <div className="flex items-center justify-end">

                <div className="notification relative cursor-pointer w-6 h-6 mx-5">
                    <div className="absolute w-2 h-2 rounded-full bg-green-500 top-0 right-0 border-5 border-white"></div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6.44V9.77" stroke="#1F2937" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M12.02 2C8.33999 2 5.35999 4.98 5.35999 8.66V10.76C5.35999 11.44 5.07999 12.46 4.72999 13.04L3.45999 15.16C2.67999 16.47 3.21999 17.93 4.65999 18.41C9.43999 20 14.61 20 19.39 18.41C20.74 17.96 21.32 16.38 20.59 15.16L19.32 13.04C18.97 12.46 18.69 11.43 18.69 10.76V8.66C18.68 5 15.68 2 12.02 2Z" stroke="#1F2937" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M15.33 18.82C15.33 20.65 13.83 22.15 12 22.15C11.09 22.15 10.25 21.77 9.65001 21.17C9.05001 20.57 8.67001 19.73 8.67001 18.82" stroke="#1F2937" stroke-width="1.5" stroke-miterlimit="10" />
                    </svg>
                </div>

                <div className="flex items-center space-x-3 cursor-pointer">
                    <div className="relative w-12 h-12 rounded-full overflow-hidden border-2 dark:border-white border-gray-900">
                        <div className="absolute w-2 h-2 rounded-full bg-green-500 top-0 right-0 border-5 border-white"></div>
                        <img src="https://images.unsplash.com/photo-1610397095767-84a5b4736cbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" alt="" className="w-full h-full object-cover" />
                    </div>
                    <div className="font-semibold text-gray-900 text-lg">
                        <div className="cursor-pointer">Admin Prokamas</div>
                    </div>
                </div>

            </div>
        </div>
    );
};
export default Header;
