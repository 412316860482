import React, { useEffect, useState } from "react";
import AppBare from "../components/appBare";
import kamasLogo from '../assets/kamas.png'
import img from '../assets/bg.png'
import { useNavigate } from "react-router-dom";
import Banner from "../components/banner";
import Kamas from "../models/kamas";
import { baseURL } from "../api/axiosConfig";
import background from "../assets/dofusLogo.jpg";

const KamasPricePage = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [cat, setCat] = useState('classic');
    const [loading, setLoading] = useState(false);

    const loadData = async (cat) => {
        setLoading(true);
        setData([]);
        try{
            const response = await Kamas.getKamasOfServer(cat);
            setData(response);
            setLoading(false);
        }catch{

        }
        
    }
    useEffect(() => {
        loadData(cat);
    }, [])
    return (
        <div className='w-full h-full flex flex-col pb-10 overflow-auto relative' 
        style={{
            backgroundImage: `url('${background}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            minHeight: '100vh'
        }}>
            <AppBare showLogo/>
            {/* <Banner /> */}
            <div className="pt-28 px-10 flex flex-row justify-center">
                <div className="px-10 rounded-xl text-white bg-gray-800 bg-opacity-80 border-4 border-gray-900 backdrop-blur-lg">
                    <h1 className="text-lg font-semibold py-5">Achetez vos kamas en toute sécurité et rapidité !
                    <br></br>Obtenez des kamas pour enrichir votre aventure !</h1>
                </div>
            </div>
            <div className="flex flex-row gap-4 py-5 px-10 ">
                <div className={`${cat == 'classic' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setCat('classic');
                        await loadData('classic');
                    }}>Classique</div>
                <div className={`${cat == 'retro' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setCat('retro');
                        await loadData('retro');
                    }}>Retro</div>
                <div className={`${cat == 'touch' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setCat('touch');
                        await loadData('touch');
                    }}>Touch</div>
                    <div className={`${cat == 'wakfu' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setCat('wakfu');
                        await loadData('wakfu');
                    }}>Wakfu</div>
                    <div className={`${cat == 'temporis' ? 'bg-main' : 'bg-gray-400'} hover:bg-main cursor-pointer text-white font-semibold py-2 px-6 rounded-full`}
                    onClick={async () => {
                        setCat('temporis');
                        await loadData('temporis');
                    }}>Temporis</div>
            </div>
            <div className="flex flex-wrap gap-10 pb-10 justify-center px-10">
                {loading ?
                    <div className="w-full flex-1 flex items-center justify-center">
                        <h1 className="text-2xl">chargement ...</h1>
                    </div> :
                    data.map(kamas =>
                        < div key={kamas.id} className="flex items-center flex-col shadow-2xl p-4 rounded-2xl bg-gray-800 bg-opacity-80 border-4 border-gray-900 backdrop-blur-lg">
                            <div className="flex flex-row justify-between gap-2 items-center">
                                <h1 className="text-base font-semibold text-bray text-center backdrop-blur-lg bg-opacity-80 bg-white py-1 px-2 rounded-xl w-36">{kamas.Server.name}</h1>
                                <h1 className="text-xs font-semibold  text-white text-center bg-green-600 rounded-xl p-2">{Number(kamas.buying_price_Skrill).toFixed(2)} €/M</h1>
                            </div>
                            <img className="object-contain flex flex-1 w-32" src={`${baseURL}/api/file/${kamas.Server.image}`} />
                            <div onClick={() => { navigate('/commande-kamas-dofus', { state: kamas }) }} className="w-full border-2 border-white text-center py-2 rounded-full text-white hover:text-gray-900  hover:bg-white cursor-pointer">Acheter</div>
                        </div>
                    )}
            </div>
        </div >
    );
}

export default KamasPricePage;