import instance from "../api/axiosConfig";

class ExchnageOrder {
    static async create(data, token) {
        try {
            // const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTY4OTcwMDkzMSwiZXhwIjoxNjg5NzA0NTMxfQ.G8Fr-nMbb3X4yWKNk74Ln7v3Vlj8rmhKQNhTt-b8NOs' }
            let response = await instance.post('/api/exchnangeOrder', data, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async getAllOrder(token) {
        try {
            let response = await instance.get('/api/exchnangeOrder', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    }


    static async getOrderByUser(idUser, token) {
        try {
            let response = await instance.get(`/api/exchnangeOrder/byUser/${idUser}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    static async update(id, data, token) {
        try {
            let response = await instance.put(`/api/exchnangeOrder/${id}`, data, { headers: { 'Authorization': `Bearer ${token}` } });
            return response.data;
        } catch (e) {
            throw e;
        }
    }


    // static async delete(id, token) {
    //     try {
    //         let response = await instance.delete(`/api/server/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
    //         return response.data;
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    // static async getAllServers() {
    //     try {
    //         let response = await instance.get('/api/server');
    //         return response.data;
    //     } catch (e) {
    //         throw e;
    //     }
    // }
}

export default ExchnageOrder;